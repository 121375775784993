import { StateCreator } from "zustand";
import { AllNftsSlice } from "store/interfaces/allNfts.interface";

export const allNftsSlice: StateCreator<AllNftsSlice, [], [], AllNftsSlice> = (
  set
) => ({
  allNfts: undefined,
  setAllNfts: (allNfts: any) =>
    set((s) => ({
      ...s,
      allNfts,
    })),
});
