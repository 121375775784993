export const DEFAULT_TOKEN_IMAGE_URL = "/images/tokens/default-token.svg";

export const INJECTIVE_BURN_WALLET =
  "inj1fj6syxy0wmvqhr3n57hwrljxwsng3k55t3yxgj";

export const NFT_TOKEN_ADDRESS = "inj1n73yuus64z0yrda9hvn77twkspc4uste9j9ydd";

export const NFT_API = "https://meta.dojo.trading/api";

export const DEPLOYER_ADDRESS = "inj1x26aln79hxrfm8c6v00208wlumrc0l6qtrtn8y";
export const DOJO_INJ_LP = "DOJO-INJ LP";
export const DOJO_INJ_LP_ADDRESS = "inj17pda96ujt7fzr3d5jmfkh4dzvrqzc0nk56kt34";

export const FORCE_ON_CHAIN_FETCH = true;
