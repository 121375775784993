import { StateCreator } from "zustand";

import {
  TxStage,
  TxState,
  TxStateSlice,
} from "../interfaces/txState.interface";
import { TransactionResult } from "../../types/txHistoryContext";

const initialTxState: TxState = {
  txType: undefined,
  txStage: undefined,
  txResult: undefined,
  summary: undefined,
};

export const txStateSlice: StateCreator<TxStateSlice, [], [], TxStateSlice> = (
  set,
  get
) => ({
  txState: {
    ...initialTxState,
  },
  resetTxState: () => set((state) => ({ ...state, txState: initialTxState })),
  updateTxStage: (stage: TxStage) =>
    set((state) => ({
      ...state,
      txState: {
        ...state.txState,
        txStage: stage,
      },
    })),
  updateTxType: (type: string) =>
    set((state) => ({
      ...state,
      txState: {
        ...state.txState,
        txType: type,
      },
    })),
  updateTxResult: (result: TransactionResult | undefined) =>
    set((state) => ({
      ...state,
      txState: {
        ...state.txState,
        txResult: result,
      },
    })),
  updateTxSummary: (summary: string | undefined) =>
    set((state) => ({
      ...state,
      txState: {
        ...state.txState,
        summary: summary,
      },
    })),
});
