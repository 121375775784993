import Axios from "axios";
// import { setupCache } from "axios-cache-interceptor";
import rateLimit from "axios-rate-limit";

const instance = rateLimit(Axios.create(), {
  maxRPS: 8,
});
const axios = instance;

// const cache = setupCache({
//   maxAge: 2500,
//   clearOnStale: true,
//   clearOnError: true,
//   readHeaders: true,
//   exclude: {
//     query: false,
//     methods: ["post", "patch", "put", "delete"],
//   },
// })

// Axios.create({
//   adapter: cache.adapter,
// })

export default axios;
