import { NETWORK, denomClient } from "libs/services";
import _ from "lodash";
import { TokenMeta } from "@injectivelabs/token-metadata";
import { Network } from "@injectivelabs/networks";
import { customDenoms } from "./denoms";
import { DOJO_INJ_LP, DOJO_INJ_LP_ADDRESS } from "./constants";

export enum TokenType {
  CW20 = "CW20",
  NATIVE = "NATIVE",
  IBC = "IBC",
}

export interface FarmToken {
  symbol: string;
  name: string;
  address: string;
  decimals: number;
  type: TokenType;
}

export interface TokenResult {
  name: string;
  symbol: string;
  decimals: number;
  total_supply: string;
  contract_addr: string;
  icon: string;
  verified: boolean;
}

enum Symbol {
  INJ = "INJ",
  USDT = "USDT",
  USDC = "USDC",
  WETH = "WETH",
  SOL = "SOL",
  TIA = "TIA",
  KAVA = "KAVA",
  DAI = "DAI",
  ATOM = "ATOM",
  WBTC = "WBTC",
  NINJA = "NINJA",
  KIRA = "KIRA",
  GINGER = "GINGER",
  TEST = "TEST",
  POINT = "POINT",
  DOJO = "DOJO",
  DINJ = "DINJ",
  ROLL = "ROLL",
  KAGE = "KAGE",
  MIB = "MIB",
  HINJ = "HINJ",
  PUNK = "PUNK",
  BITS = "BITS",
  IKINGS = "IKINGS",
  FROG = "FROG",
  SUSHI = "SUSHI",
}

export const serializeToken = (
  symbol: string,
  name: string,
  address: string,
  decimals: number,
  type: TokenType
): FarmToken => {
  return {
    symbol,
    name,
    address,
    decimals,
    type,
  };
};

// Mainnet tokens
export const POINT = serializeToken(
  "POINT",
  "POINT Token",
  "inj1l73x8hh6du0h8upp65r7ltzpj5twadtp5490n0",
  18,
  TokenType.CW20
);
export const DOJO = serializeToken(
  "DOJO",
  "Dojo Token",
  "inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923",
  18,
  TokenType.CW20
);
export const INJ = serializeToken(
  "INJ",
  "Injective",
  "inj",
  18,
  TokenType.NATIVE
);
export const FROG = serializeToken(
  "FROG",
  "Injective Frogs",
  "inj104y5dpcdtga79tczv6rg3rsekx4hasw2k83h5s",
  18,
  TokenType.CW20
);
export const BITS = serializeToken(
  "BITS",
  "bits",
  "factory/inj10gcvfpnn4932kzk56h5kp77mrfdqas8z63qr7n/bits",
  6,
  TokenType.NATIVE
);
export const dINJ = serializeToken(
  "dINJ",
  "Dojo Staked Injective",
  "inj134wfjutywny9qnyux2xgdmm0hfj7mwpl39r3r9",
  18,
  TokenType.CW20
);
export const hINJ = serializeToken(
  "hINJ",
  "Hydro Staked Injective",
  "inj18luqttqyckgpddndh8hvaq25d5nfwjc78m56lc",
  18,
  TokenType.CW20
);
export const ROLL = serializeToken(
  "ROLL",
  "NinjaRoll",
  "inj1qv98cmfdaj5f382a0klq7ps4mnjp6calzh20h3",
  18,
  TokenType.CW20
);
export const DOJO_ROLL = serializeToken(
  "DOJO-ROLL",
  "DOJO ROLL LP",
  "inj17m8xuqntg5sc3m78jzajte5r4my4erwhujq3uh",
  6,
  TokenType.CW20
);
export const KAGE = serializeToken(
  "KAGE",
  "Kage Finance",
  "inj1l49685vnk88zfw2egf6v65se7trw2497wsqk65",
  18,
  TokenType.CW20
);
export const PUNK = serializeToken(
  "PUNK",
  "Punk Token",
  "inj1wmrzttj7ms7glplek348vedx4v2ls467n539xt",
  18,
  TokenType.CW20
);
export const NINJA = serializeToken(
  "NINJA",
  "Dog Wif Nunchucks",
  "factory/inj1xtel2knkt8hmc9dnzpjz6kdmacgcfmlv5f308w/ninja",
  6,
  TokenType.NATIVE
);
export const KIRA = serializeToken(
  "KIRA",
  "KIRA",
  "factory/inj1xy3kvlr4q4wdd6lrelsrw2fk2ged0any44hhwq/KIRA",
  6,
  TokenType.NATIVE
);
export const GINGER = serializeToken(
  "GINGER",
  "GINGER",
  "factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER",
  6,
  TokenType.NATIVE
);
export const USDT = serializeToken(
  "USDT",
  "USD Tether",
  "peggy0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  TokenType.NATIVE
);
export const WETH = serializeToken(
  "WETH",
  "Wrapped Ether",
  "peggy0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  18,
  TokenType.NATIVE
);
export const WBTC = serializeToken(
  "WBTC",
  "Wrapped BTC",
  "peggy0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  8,
  TokenType.NATIVE
);
export const USDC = serializeToken(
  "USDC",
  "USD Coin",
  "peggy0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  6,
  TokenType.NATIVE
);

export const TIA = serializeToken(
  "TIA",
  "Celestia",
  "ibc/F51BB221BAA275F2EBF654F70B005627D7E713AFFD6D86AFD1E43CAA886149F4",
  6,
  TokenType.NATIVE
);
export const SOL = serializeToken(
  "SOL",
  "Solana",
  "factory/inj14ejqjyq8um4p3xfqj74yld5waqljf88f9eneuk/inj1sthrn5ep8ls5vzz8f9gp89khhmedahhdkqa8z3",
  8,
  TokenType.NATIVE
);
export const ATOM = serializeToken(
  "ATOM",
  "Cosmos",
  "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9",
  6,
  TokenType.NATIVE
);
export const MIB = serializeToken(
  "MIB",
  "Men In Black",
  "factory/inj1s9pckznjz4hmgtxu5t9gerxtalch7wtle4y3a6/MIB",
  6,
  TokenType.NATIVE
);
export const IKINGS = serializeToken(
  "IKINGS",
  "Injective Kings",
  "factory/inj1mt876zny9j6xae25h7hl7zuqf7gkx8q63k0426/IKINGS",
  6,
  TokenType.NATIVE
);
export const SUSHI = serializeToken(
  "SUSHI",
  "SUSHI FIGHTER Token",
  "inj1n73yuus64z0yrda9hvn77twkspc4uste9j9ydd",
  18,
  TokenType.CW20
);

const EXCLUDE_TOKENS = [Symbol.DAI, Symbol.KAVA];

export const TOKEN_SYMBOLS = [Symbol.INJ, Symbol.KAVA, Symbol.DAI];

export const TESTNET_TOKEN_ADDRESSES = {
  [Symbol.USDT]: "peggy0x87aB3B4C8661e07D6372361211B96ed4Dc36B1B5",
  [Symbol.WETH]: "factory/inj17vytdwqczqz72j65saukplrktd4gyfme5agf6c/weth",
  [Symbol.USDC]: "factory/inj17vytdwqczqz72j65saukplrktd4gyfme5agf6c/usdc",
  [Symbol.ATOM]: "factory/inj17vytdwqczqz72j65saukplrktd4gyfme5agf6c/atom",
  [Symbol.DOJO]: "inj1375v9e5awxf340cgv2pzh4seu074lxw0d092gd",
  [Symbol.ROLL]: "inj1damsnn4rxkqhxpe890u2guzj72ndmvmj228hjd",
  [Symbol.SUSHI]: "inj1634vsflevg040sx4yhr3pva5t0wwlqzdlkk5v2",
};

export const MAINNET_TOKEN_ADDRESSES = {
  [Symbol.USDT]: "peggy0xdAC17F958D2ee523a2206206994597C13D831ec7",
  [Symbol.WETH]: "peggy0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  [Symbol.WBTC]: "peggy0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  [Symbol.USDC]: "peggy0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [Symbol.NINJA]: "factory/inj1xtel2knkt8hmc9dnzpjz6kdmacgcfmlv5f308w/ninja",
  [Symbol.KIRA]: "factory/inj1xy3kvlr4q4wdd6lrelsrw2fk2ged0any44hhwq/KIRA",
  [Symbol.TIA]:
    "ibc/F51BB221BAA275F2EBF654F70B005627D7E713AFFD6D86AFD1E43CAA886149F4",
  [Symbol.ATOM]:
    "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9",
  [Symbol.SOL]:
    "factory/inj14ejqjyq8um4p3xfqj74yld5waqljf88f9eneuk/inj1sthrn5ep8ls5vzz8f9gp89khhmedahhdkqa8z3",
  [Symbol.GINGER]: "factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER",
  [Symbol.DOJO]: "inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923",
  [Symbol.DINJ]: "inj134wfjutywny9qnyux2xgdmm0hfj7mwpl39r3r9",
  [Symbol.HINJ]: "inj18luqttqyckgpddndh8hvaq25d5nfwjc78m56lc",
  // [Symbol.ROLL]: "inj1qv98cmfdaj5f382a0klq7ps4mnjp6calzh20h3",
  [Symbol.KAGE]: "inj1l49685vnk88zfw2egf6v65se7trw2497wsqk65",
  [Symbol.MIB]: "factory/inj1s9pckznjz4hmgtxu5t9gerxtalch7wtle4y3a6/MIB",
  [Symbol.PUNK]: "inj1wmrzttj7ms7glplek348vedx4v2ls467n539xt",
  [Symbol.BITS]: "factory/inj10gcvfpnn4932kzk56h5kp77mrfdqas8z63qr7n/bits",
  [Symbol.IKINGS]: "factory/inj1mt876zny9j6xae25h7hl7zuqf7gkx8q63k0426/IKINGS",
  [Symbol.FROG]: "inj104y5dpcdtga79tczv6rg3rsekx4hasw2k83h5s",
  [Symbol.SUSHI]: "inj1n73yuus64z0yrda9hvn77twkspc4uste9j9ydd",
};

export const SPECIAL_DENOM =
  NETWORK === Network.Testnet
    ? Object.values(TESTNET_TOKEN_ADDRESSES)
    : Object.values(MAINNET_TOKEN_ADDRESSES);

function processToken(item: TokenMeta): TokenResult | TokenResult[] {
  if (item.cw20) {
    return [
      {
        name: item.name,
        symbol: item.symbol,
        contract_addr: item.cw20.address,
        icon: item.logo,
        verified: true,
        decimals: item.cw20.decimals,
        total_supply: "0",
      },
    ];
  } else if (item.cw20s) {
    return item.cw20s.map((cw20) => ({
      name: item.name,
      symbol: cw20.symbol,
      contract_addr: cw20.address,
      icon: item.logo,
      verified: true,
      decimals: cw20.decimals,
      total_supply: "0",
    }));
  } else if (item.ibc) {
    return [
      {
        name: item.name,
        symbol: item.symbol,
        contract_addr: `ibc/${item.ibc.hash}`,
        // contract_addr: item.ibc.baseDenom || `ibc/${item.ibc.hash}`,
        icon: item.logo,
        verified: true,
        decimals: item.decimals ?? 18,
        total_supply: "0",
      },
    ];
  }

  // This is final case for catching default native token: in this case, INJ
  return {
    name: item?.name,
    symbol: item?.symbol,
    contract_addr: item?.symbol?.toLowerCase(),
    icon: item?.logo,
    verified: true,
    decimals: item?.decimals ?? 18,
    total_supply: "0",
  };
}

const INIT_TOKENS: TokenResult[] = _.flatMap(
  TOKEN_SYMBOLS.map((symbol) => {
    const item = denomClient.getTokenMetaDataBySymbol(symbol);
    return processToken(item);
  }).concat(
    SPECIAL_DENOM.map((denom) => {
      const item = denomClient.getDenomTokenInfo(denom);

      if (!item) {
        const customItem = customDenoms[denom];
        if (!customItem) {
          denomClient.getDenomToken(denom).then((res) => {});
        }

        return {
          name: customItem?.name,
          symbol: customItem?.symbol,
          contract_addr: customItem?.denom,
          icon: customItem?.logo,
          verified: true,
          decimals: customItem?.decimals,
          total_supply: "0",
        };
      }

      return {
        name: item?.meta?.name,
        symbol: item?.meta?.symbol,
        contract_addr: item?.denom,
        icon: item?.meta?.logo,
        verified: true,
        decimals: item?.meta?.decimals,
        total_supply: "0",
      };
    })
  )
);

const EXTRA_TOKENS = [
  {
    name: "DOJO-INJ LP",
    symbol: DOJO_INJ_LP,
    contract_addr: DOJO_INJ_LP_ADDRESS,
    icon: `/images/tokens/${DOJO_INJ_LP_ADDRESS}.svg`,
    verified: true,
    decimals: 6,
    total_supply: "0",
  },
  {
    name: "ROLL",
    symbol: "ROLL",
    contract_addr: "inj1qv98cmfdaj5f382a0klq7ps4mnjp6calzh20h3",
    icon: "/images/tokens/ninjaroll.svg",
    verified: true,
    decimals: 18,
    total_supply: "0",
  },
  {
    name: "KAGE",
    symbol: "KAGE",
    contract_addr: KAGE.address,
    icon: `/images/tokens/${KAGE.address}.svg`,
    verified: true,
    decimals: 18,
    total_supply: "0",
  },
  {
    name: "MIB",
    symbol: "MIB",
    contract_addr: MIB.address,
    icon: `/images/tokens/mib.jpg`,
    verified: true,
    decimals: 6,
    total_supply: "0",
  },
  {
    name: "PUNK",
    symbol: "PUNK",
    contract_addr: PUNK.address,
    icon: `/images/tokens/${PUNK.address}.svg`,
    verified: true,
    decimals: 18,
    total_supply: "0",
  },
  {
    name: "BITS",
    symbol: "BITS",
    contract_addr: BITS.address,
    icon: `bits.svg`,
    verified: true,
    decimals: 6,
    total_supply: "0",
  },
  {
    name: "IKINGS",
    symbol: "IKINGS",
    contract_addr: IKINGS.address,
    icon: `IKINGS.png`,
    verified: true,
    decimals: 6,
    total_supply: "0",
  },
  {
    name: "SUSHI",
    symbol: "SUSHI",
    contract_addr: SUSHI.address,
    icon: `sushi.svg`,
    verified: true,
    decimals: 18,
    total_supply: "0",
  },
];

export const EXCLUDE_FROM_SWAP = [];

export const ALL_TOKENS = INIT_TOKENS.filter(
  (token) => !EXCLUDE_TOKENS.includes(token.symbol as Symbol)
).concat(EXTRA_TOKENS);
