import useWallet from "./useWallet";
import injectiveNetworks from "../constants/networks";

const useNetwork = () => {
  const { network } = useWallet();

  const getFinderUrl = (address: string, path: string = "account") =>
    network.chainID === "injective-888"
      ? `https://testnet.explorer.injective.network/${path}/${address}`
      : `https://explorer.injective.network/${path}/${address}`;

  return {
    ...network,
    ...injectiveNetworks[network.name],
    getFinderUrl,
  };
};

export default useNetwork;
