/**
* This file was automatically generated by @cosmwasm/ts-codegen@0.35.3.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the @cosmwasm/ts-codegen generate command to regenerate this file.
*/

import * as _0 from "./StakingPool.types";
import * as _1 from "./StakingPool.message-builder";
export namespace contracts {
  export const StakingPool = { ..._0,
    ..._1
  };
}