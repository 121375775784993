import "../styles/global.css";
import { useEffect } from "react";
import Header from "./Header";
import { useNft } from "hooks/useNft";
import useWallet from "hooks/useWallet";
import useAllNftStore from "../store/allNftsStore";
// import { usePrices } from "hooks/usePrices";
import { useTokenSymbolsMap } from "rest/useDashboardAPI";
import useStore from "../store";
import { useFarms } from "hooks/useFarms";

const LOCALSTORAGE_KEYS_TO_CLEAR = ["sushifighter"];

const App = () => {
  const { allNfts } = useAllNftStore();
  const { fetchAllNfts, fetchNft } = useNft();
  const { wallet } = useWallet();

  const selectedFarmsVersion = useStore((s) => s.selectedFarmsVersion);
  // const { fetchPrices } = usePrices();
  const { fetchFarms } = useFarms();

  useTokenSymbolsMap();
  // const cachedNftWallet = useStore((s) => s.nftWallet);

  useEffect(() => {
    // Define your condition for clearing specific keys
    LOCALSTORAGE_KEYS_TO_CLEAR.forEach((key) => {
      localStorage.removeItem(key);
    });
  }, []);

  // useEffect(() => {
  //   const run = async () => {
  //     if (tokenSymbolsMap?.["inj"] !== undefined) {
  //       await fetchPrices();
  //     }
  //   };

  //   const intervalId = setInterval(() => {
  //     run();
  //   }, 30000);

  //   run();

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [tokenSymbolsMap?.["inj"] !== undefined]);

  useEffect(() => {
    const refreshFarms = async () => {
      await fetchFarms({
        version: selectedFarmsVersion,
        walletAddress: wallet.address,
      });
    };
    const intervalId = setInterval(() => {
      refreshFarms();
    }, 30000);

    refreshFarms();

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedFarmsVersion, wallet.address]);

  useEffect(() => {
    const run = async () => {
      // console.log("fetching all nft data");
      await fetchAllNfts();
    };

    const intervalId = setInterval(() => {
      run();
    }, 600000);

    run();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const run = async () => {
      // console.log("fetching new nft data");
      await fetchNft(wallet?.address);
    };
    run();
  }, [wallet?.address, allNfts?.length]);

  return <Header />;
};

export default App;
