import Modal from "./ModalV2";
import ConnectList from "./ConnectList";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ConnectWalletModal = (props: Props) => {
  const { open, onClose } = props;
  return (
    <Modal title="SELECT WALLET" open={open} onClose={onClose}>
      <ConnectList />
    </Modal>
  );
};

export default ConnectWalletModal;
