import axios from "../rest/request";
import _ from "lodash";

export const multicallFetcher =
  (
    multicall: string,
    getURL: (val: string, obj: any) => string,
    chunkCount = 20
  ) =>
  (...args: any) => {
    const [queries] = args[0];
    const promises = [];

    const chunks = _.chunk(queries, chunkCount);

    for (const chunk of chunks) {
      const url = getURL(multicall, {
        aggregate: {
          queries: chunk,
        },
      });
      promises.push(axios.get(url));
    }
    // TODO: consider using Promise.allSettled() to further optimise
    return Promise.all(promises);
  };
