export enum WalletConnectStatus {
  connecting = "Connecting",
  disconnected = "Disconnected",
  idle = "Idle",
  connected = "Connected",
}

export enum Availability {
  ALL = "All",
  AVAILABLE = "Available",
  // BURNT = "Burnt",
}

export enum MySortBy {
  "RANK" = "Rank",
  "ID" = "ID",
  "OMA" = "OMA",
  "STAKEDURATION" = "Exploration",
}

export enum SortBy {
  "RANK" = "Rank",
  "ID" = "ID",
  "OMA" = "OMA",
}
