import Modal from "./ModalV2";

interface Props {
  open: boolean;
  onClose: () => void;
}

const PriceChartModal = (props: Props) => {
  return (
    <Modal
      title={`OPEN SUSHI PRICE CHART`}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}>
      <div className="w-full flex flex-col gap-y-3">
        <a
          href="https://dexscreener.com/injective/inj12eca9xszt84qm9tztyuje96nn3v2wd3v4yrzge"
          target="_blank"
          rel="noreferrer"
          className="border border-white/40 hover:border-white transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between gap-x-3">
          <div className="text-white font-semibold tracking-wide flex items-center gap-x-1">
            DEX SCREENER
          </div>
          <img
            src="/images/dexscreener.svg"
            alt="DEX Screener"
            className="w-12 h-12 p-1"
          />
        </a>
        <a
          href="https://coinhall.org/injective/inj12eca9xszt84qm9tztyuje96nn3v2wd3v4yrzge"
          target="_blank"
          rel="noreferrer"
          className="border border-white/40 hover:border-white transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between gap-x-3">
          <div className="text-white font-semibold tracking-wide flex items-center gap-x-1">
            COINHALL
          </div>
          <img
            src="/images/coinhall.svg"
            alt="Coinhall"
            className="w-12 h-12 rounded-lg"
          />
        </a>
      </div>
    </Modal>
  );
};

export default PriceChartModal;
