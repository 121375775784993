import Modal from "./ModalV2";

interface Props {
  open: boolean;
  onClose: () => void;
}

const BuyModal = (props: Props) => {
  return (
    <Modal
      title={`CHOOSE YOUR PURCHASE ROUTE`}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}>
      <div className="w-full flex flex-col gap-y-3">
        <a
          href="https://dojo.trading/swap?type=swap&from=inj&to=inj1n73yuus64z0yrda9hvn77twkspc4uste9j9ydd"
          target="_blank"
          rel="noreferrer"
          className="border border-white/40 hover:border-white transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between gap-x-3">
          <div className="text-white font-semibold tracking-wide flex items-center gap-x-1">
            BUY{" "}
            <img
              src="/images/tokens/sushi.webp"
              className="w-5 h-5"
              alt="sushi"
            />{" "}
            $SUSHI FROM DOJOSWAP
          </div>
          <img
            src="/images/dojoswap-icon.svg"
            alt="SUSHI"
            className="w-12 h-12 p-1"
          />
        </a>
        <a
          href="https://injective.talis.art/collection/65d7825fefc5876b5878b430"
          target="_blank"
          rel="noreferrer"
          className="border border-white/40 hover:border-white transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between gap-x-3">
          <div className="text-white font-semibold tracking-wide flex items-center gap-x-1">
            BUY{" "}
            <img
              src="/images/tokens/sushi-fighter.webp"
              className="w-5 h-5"
              alt="sushi fighter"
            />{" "}
            SUSHI FIGHTER NFT FROM TALIS
          </div>
          <img
            src="/images/talis-icon-dark.svg"
            alt="SUSHI FIGHTER"
            className="w-12 h-12 rounded-lg"
          />
        </a>
      </div>
    </Modal>
  );
};

export default BuyModal;
