import { create, StoreApi, UseBoundStore } from "zustand";
import { AllNftsSlice } from "./interfaces/allNfts.interface";
import { allNftsSlice } from "./slices/allNftsSlice";

let useAllNftStore: UseBoundStore<StoreApi<AllNftsSlice>>;

useAllNftStore = create<AllNftsSlice>()((...a) => ({
  ...allNftsSlice(...a),
}));

export default useAllNftStore;
