import { WalletControllerChainOptions } from "@terra-money/wallet-provider";

const fallbackData = {
  mainnet: {
    name: "mainnet",
    api: "https://external.api.injective.network",
    chainID: "injective-1",
    hive: "https://phoenix-hive.terra.dev/graphql",
    lcd: "https://inj18583.allnodes.me:1317/MOdKBWvqaA0m0ig3",
    walletconnectID: 1,
  },
  testnet: {
    api: "https://external.api.injective.network",
    chainID: "injective-888",
    hive: "https://phoenix-hive.terra.dev/graphql",
    lcd: "https://testnet.sentry.lcd.injective.network:443",
    name: "testnet",
    walletconnectID: 0,
  },
};
export async function getChainOptions() {
  let data: any;
  data = fallbackData;
  const chains = Object.values(data);
  const walletConnectChainIds = chains.reduce<
    WalletControllerChainOptions["walletConnectChainIds"]
  >((result, network: any) => {
    if (typeof network.walletconnectID === "number") {
      result[network.walletconnectID] = network;
    } else if (!result[1] && network.name === "mainnet") {
      result[1] = network;
    } else if (!result[0] && network.name === "testnet") {
      result[0] = network;
    }
    return result;
  }, {});
  const chainOptions: WalletControllerChainOptions = {
    defaultNetwork: walletConnectChainIds[1],
    walletConnectChainIds,
  };
  return chainOptions;
}
