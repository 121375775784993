import MESSAGE from "../lang/MESSAGE.json";
import Connected from "./Connected";
import useStore from "../store";
import { WalletConnectStatus } from "../types/enums";

const HeaderConnectMobile = () => {
  const wallet = useStore((s) => s.wallet);
  const setShowWalletConnectModal = useStore((s) => s.setShowWalletConnectModal);
  const handleClick = () => setShowWalletConnectModal(true);

  return !(wallet.connectStatus === WalletConnectStatus.connected) ? (
    <button
      id="connect"
      className="uppercase block rounded-xl px-3 py-2 text-base font-extrabold tracking-wide leading-7 w-full border text-white"
      onClick={handleClick}>
      <span>{MESSAGE.Wallet.ConnectWallet}</span>
    </button>
  ) : (
    <Connected
      className={
        "flex text-sm font-semibold px-[15px] py-2 rounded-[14px] hover:no-underline p-2 border-1"
      }
    />
  );
};

export default HeaderConnectMobile;
