import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WalletConnectProvider from "./components/WalletConnectProvider";
import { WagmiConfig, createConfig, mainnet } from "wagmi";
import { createPublicClient, http } from "viem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "components/App";
const Home = lazy(() => import("components/Home"));
const About = lazy(() => import("components/About"));
const Gallery = lazy(() => import("components/Gallery"));
const MyNFTs = lazy(() => import("components/MyNFTs"));
const Analytics = lazy(() => import("components/Analytics"));

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http(),
  }),
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig config={config}>
        <WalletConnectProvider>
          <App />
          <ToastContainer pauseOnHover theme="dark" />
          <Routes>
            <Route
              path="/"
              element={
                <Suspense>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <Suspense>
                  <About />
                </Suspense>
              }
            />
            <Route
              path="/gallery"
              element={
                <Suspense>
                  <Gallery />
                </Suspense>
              }
            />
            <Route
              path="/my-nfts"
              element={
                <Suspense>
                  <MyNFTs />
                </Suspense>
              }
            />
            <Route
              path="/analytics"
              element={
                <Suspense>
                  <Analytics />
                </Suspense>
              }
            />
          </Routes>
        </WalletConnectProvider>
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);
