import { create, StoreApi, UseBoundStore } from "zustand";
import { PricesSlice } from "./interfaces/prices.interface";
import { pricesSlice } from "./slices/pricesSlice";

let usePriceStore: UseBoundStore<StoreApi<PricesSlice>>;

usePriceStore = create<PricesSlice>()((...a) => ({
  ...pricesSlice(...a),
}));

export default usePriceStore;
