// This package is used to cache custom denoms and tokens.

import { Network } from "@injectivelabs/networks"
import { NETWORK } from "libs/services"

export const MAINNET_CUSTOM_DENOMS = {
  "factory/inj1xtel2knkt8hmc9dnzpjz6kdmacgcfmlv5f308w/ninja": {
    denom: "factory/inj1xtel2knkt8hmc9dnzpjz6kdmacgcfmlv5f308w/ninja",
    name: "Dog wif nunchucks",
    display: "NINJA",
    description:
      "The first meme coin on Injective. It’s a dog, but he has nunchucks",
    symbol: "NINJA",
    decimals: 6,
    logo: "https://i.ibb.co/Yf7C1BD/file-fa5-Sk9-Zh-Cv7tb9y-K6wo-Dxf-UU-2.png",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
  "factory/inj1xy3kvlr4q4wdd6lrelsrw2fk2ged0any44hhwq/KIRA": {
    denom: "factory/inj1xy3kvlr4q4wdd6lrelsrw2fk2ged0any44hhwq/KIRA",
    name: "KIRA",
    display: "KIRA",
    description: "Injective’s Favorite Cat",
    symbol: "KIRA",
    decimals: 6,
    logo: "https://explorer.injective.network/vendor/@injectivelabs/token-metadata/kira.jpeg",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
  "factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER": {
    denom: "factory/inj172ccd0gddgz203e4pf86ype7zjx573tn8g0df9/GINGER",
    name: "GINGER",
    display: "GINGER",
    description: "Eric Chen's Favorite Cat",
    symbol: "GINGER",
    decimals: 6,
    logo: "https://explorer.injective.network/vendor/@injectivelabs/token-metadata/ginger.png",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
  inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923: {
    denom: "inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923",
    name: "DOJO",
    display: "DOJO",
    description: "DojoSwap",
    symbol: "DOJO",
    decimals: 18,
    logo: "/images/tokens/dojo.svg",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
  inj134wfjutywny9qnyux2xgdmm0hfj7mwpl39r3r9: {
    denom: "inj134wfjutywny9qnyux2xgdmm0hfj7mwpl39r3r9",
    name: "dINJ",
    display: "dINJ",
    description: "Dojo Staked INJ",
    symbol: "dINJ",
    decimals: 18,
    logo: "/images/tokens/dinj.svg",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
  babyDojo: {
    denom: "babyDojo",
    name: "babyDOJO",
    display: "babyDOJO",
    description: "babyDOJO Reflection Token",
    symbol: "babyDOJO",
    decimals: 18,
    logo: "/images/tokens/babydojo.svg",
  },
  inj18luqttqyckgpddndh8hvaq25d5nfwjc78m56lc: {
    denom: "inj18luqttqyckgpddndh8hvaq25d5nfwjc78m56lc",
    name: "hINJ",
    display: "hINJ",
    description: "Hydro Staked INJ",
    symbol: "hINJ",
    decimals: 18,
    logo: "/images/tokens/hinj.svg",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
}
export const TESTNET_CUSTOM_DENOMS = {
  inj16ncdyyhwl7gz6q6vvz447fyn70myc22vw45zvt: {
    denom: "inj16ncdyyhwl7gz6q6vvz447fyn70myc22vw45zvt",
    name: "POINT",
    display: "POINT",
    description: "Testnet version of DojoSwap's POINTs",
    symbol: "POINT",
    decimals: 18,
    logo: "",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
  inj1375v9e5awxf340cgv2pzh4seu074lxw0d092gd: {
    denom: "inj1375v9e5awxf340cgv2pzh4seu074lxw0d092gd",
    name: "DOJO",
    display: "DOJO",
    description: "DojoSwap Token",
    symbol: "DOJO",
    decimals: 18,
    logo: "/images/tokens/dojo.svg",
    coinGeckoId: "",
    tokenType: "tokenFactory",
    tokenVerification: "internal",
  },
}
export const customDenoms =
  NETWORK === Network.Testnet ? TESTNET_CUSTOM_DENOMS : MAINNET_CUSTOM_DENOMS
