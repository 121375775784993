import { StateCreator } from "zustand"
import {
  PricesBySymbol,
  PricesByTokenAddress,
  PricesSlice,
  TokenSymbolsMap,
} from "store/interfaces/prices.interface"

export const pricesSlice: StateCreator<PricesSlice, [], [], PricesSlice> = (
  set,
) => ({
  pricesByTokenAddress: undefined,
  pricesBySymbol: undefined,
  setPricesByTokenAddress: (prices: PricesByTokenAddress) =>
    set((s) => ({
      ...s,
      pricesByTokenAddress: {
        ...s.pricesByTokenAddress,
        ...prices,
      },
    })),
  setPricesBySymbol: (prices: PricesBySymbol) => {
    set((s) => ({
      ...s,
      pricesBySymbol: {
        ...s.pricesBySymbol,
        ...prices,
      },
    }))
  },
  tokenSymbolsMap: undefined,
  setTokensSymbolsMap: (tokenSymbolsMap: TokenSymbolsMap) =>
    set((s) => ({
      ...s,
      tokenSymbolsMap: {
        ...s.tokenSymbolsMap,
        ...tokenSymbolsMap,
      },
    })),
})
