import { StateCreator } from "zustand";
import {
  MyNftData,
  MyNftDataSlice,
} from "store/interfaces/myNftdata.interface";

export const myNftDataSlice: StateCreator<
  MyNftDataSlice,
  [],
  [],
  MyNftDataSlice
> = (set) => ({
  nftWallet: undefined,
  setNftWallet: (wallet: string) =>
    set((s) => ({
      ...s,
      nftWallet: wallet,
    })),
  isNftLoading: false,
  setIsNftLoading: (isLoading: boolean) =>
    set((s) => ({
      ...s,
      isNftLoading: isLoading,
    })),
  myNftData: undefined,
  setMyNftData: (data: MyNftData) =>
    set((s) => ({
      ...s,
      myNftData: data,
    })),
});
