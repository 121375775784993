export const TRAITS_DICTIONARY = {
  overlays: {
    trait_type: "Overlays",
    traits: [
      { name: "None", appearance: 90 },
      { name: "Autumn", image: "Autumn.png", appearance: 2.5 },
      { name: "Spring", image: "Spring.png", appearance: 2.5 },
      { name: "Summer", image: "Summer.png", appearance: 2.5 },
      { name: "Winter", image: "Winter.png", appearance: 2.5 },
    ],
  },
  background: {
    trait_type: "Background",
    traits: [
      { name: "Blue Floral", image: "Blue Floral.jpg", appearance: 1 },
      {
        name: "Blue Red Floral",
        image: "Blue Red Floral.jpg",
        appearance: 1,
      },
      {
        name: "Blue Yellow Gradient",
        image: "Blue Yellow Gradient.jpg",
        appearance: 4,
      },
      {
        name: "Blue Yellow Pink Gradient",
        image: "Blue Yellow Pink Gradient.jpg",
        appearance: 1,
      },
      { name: "Brass", image: "Brass.jpg", appearance: 7 },
      { name: "Cadet Grey", image: "Cadet Grey.jpg", appearance: 7 },
      { name: "Chinese Blue", image: "Chinese Blue.jpg", appearance: 7 },
      { name: "Cyan Azure", image: "Cyan Azure.jpg", appearance: 7 },
      { name: "Dark Indigo", image: "Dark Indigo.jpg", appearance: 7 },
      { name: "Green Floral", image: "Green Floral.jpg", appearance: 1 },
      { name: "Green Gradient", image: "Green Gradient.jpg", appearance: 4 },
      {
        name: "Green Orange Floral",
        image: "Green Orange Floral.jpg",
        appearance: 1,
      },
      { name: "Magenta", image: "Magenta.jpg", appearance: 5 },
      {
        name: "Midnight Floral",
        image: "Midnight Floral.jpg",
        appearance: 0.5,
      },
      { name: "Mindaro", image: "Mindaro.jpg", appearance: 2 },
      { name: "Mustard Floral", image: "Mustard Floral.jpg", appearance: 1 },
      {
        name: "Orange Red Gradient",
        image: "Orange Red Gradient.jpg",
        appearance: 4,
      },
      { name: "Pale Blue", image: "Pale Blue.jpg", appearance: 2 },
      {
        name: "Pale Cornflower Blue",
        image: "Pale Cornflower Blue.jpg",
        appearance: 7,
      },
      {
        name: "Pink Beige Gradient",
        image: "Pink Beige Gradient.jpg",
        appearance: 4,
      },
      {
        name: "Pink Blue Gradient",
        image: "Pink Blue Gradient.jpg",
        appearance: 4,
      },
      {
        name: "Pink Blue Yellow Gradient",
        image: "Pink Blue Yellow Gradient.jpg",
        appearance: 1,
      },
      { name: "Pink Mix", image: "Pink Mix.jpg", appearance: 0.5 },
      {
        name: "Purple Blue Gradient",
        image: "Purple Blue Gradient.jpg",
        appearance: 4,
      },
      { name: "Rose Floral", image: "Rose Floral.jpg", appearance: 1.5 },
      { name: "Sage Floral", image: "Sage Floral.jpg", appearance: 1.5 },
      { name: "Sky Gradient", image: "Sky Gradient.jpg", appearance: 4 },
      { name: "Weldon Blue", image: "Weldon Blue.jpg", appearance: 7 },
      { name: "Yellow", image: "Yellow.jpg", appearance: 3 },
    ],
  },
  bodyShape: {
    trait_type: "Body Shape",
    traits: [
      {
        name: "Circle",
        image: "Circle Body.png",
        appearance: 25,
        subTraits: {
          back: {
            trait_type: "Back",
            traits: [
              { name: "None", appearance: 80 },
              {
                name: "Ajitama (Circle)",
                image: "Ajitama (Circle).png",
                appearance: 2,
              },
              { name: "Beef", image: "Beef.png", appearance: 2 },
              {
                name: "Cooked Crab",
                image: "Cooked Crab.png",
                appearance: 2,
              },
              {
                name: "Crab Claw (Circle)",
                image: "Crab Claw (Circle).png",
                appearance: 1,
              },
              { name: "Fatty Tuna", image: "Fatty Tuna.png", appearance: 2 },
              {
                name: "Green Gourd",
                image: "Green Gourd.png",
                appearance: 1,
              },
              { name: "Mackerel", image: "Mackerel.png", appearance: 2 },
              {
                name: "Octopus (Circle)",
                image: "Octopus (Circle).png",
                appearance: 2,
              },
              {
                name: "Salmon (Circle)",
                image: "Salmon (Circle).png",
                appearance: 2,
              },
              {
                name: "Salmon Waist",
                image: "Salmon Waist.png",
                appearance: 2,
              },
              {
                name: "Squid (Circle)",
                image: "Squid (Circle).png",
                appearance: 2,
              },
            ],
          },
          clothes: {
            trait_type: "Clothes",
            traits: [
              { name: "None", appearance: 15 },
              {
                name: "Red Blue Belt (Circle)",
                image: "Red Blue Belt (Circle).png",
                appearance: 7,
              },
              {
                name: "Green Blue Belt (Circle)",
                image: "Green Blue Belt (Circle).png",
                appearance: 7,
              },
              {
                name: "Brown Red Belt (Circle)",
                image: "Brown Red Belt (Circle).png",
                appearance: 6,
              },
              {
                name: "Brown Belt (Circle)",
                image: "Brown Belt (Circle).png",
                appearance: 7,
              },
              {
                name: "Fast Food Employee",
                image: "Fast Food Employee.png",
                appearance: 3,
              },
              {
                name: "Fast Food Manager",
                image: "Fast Food Manager.png",
                appearance: 1.5,
              },
              {
                name: "Polka Undies",
                image: "Polka Undies.png",
                appearance: 4,
              },
              {
                name: "Blue Warrior",
                image: "Blue Warrior.png",
                appearance: 4,
              },
              { name: "Celebrity", image: "Celebrity.png", appearance: 1.5 },
              { name: "Commoner", image: "Commoner.png", appearance: 6 },
              { name: "Dancer", image: "Dancer.png", appearance: 1.5 },
              { name: "Green Coat", image: "Green Coat.png", appearance: 5.5 },
              { name: "Green Mino", image: "Green Mino.png", appearance: 4.5 },
              {
                name: "Handheld Salmon (Circle)",
                image: "Handheld Salmon (Circle).png",
                appearance: 2.5,
              },
              {
                name: "Inari (Circle)",
                image: "Inari (Circle).png",
                appearance: 0.5,
              },
              { name: "Kimono", image: "Kimono.png", appearance: 2.5 },
              { name: "Neon Green", image: "Neon Green.png", appearance: 4 },
              { name: "Neon Pink", image: "Neon Pink.png", appearance: 4 },
              {
                name: "Orange Warrior",
                image: "Orange Warrior.png",
                appearance: 4,
              },
              { name: "Red Coat", image: "Red Coat.png", appearance: 4 },
              { name: "Sauna", image: "Sauna.png", appearance: 3 },
              {
                name: "Waistband of Bravery",
                image: "Waistband of Bravery.png",
                appearance: 2,
              },
            ],
          },
          face: {
            trait_type: "Face",
            traits: [
              { name: "Beachy", image: "Beachy.png", appearance: 8 },
              { name: "Cute", image: "Cute.png", appearance: 14 },
              { name: "Disbelief", image: "Disbelief.png", appearance: 12 },
              { name: "Dislike", image: "Dislike.png", appearance: 14 },
              { name: "Dreamy", image: "Dreamy.png", appearance: 8 },
              { name: "Fierce", image: "Fierce.png", appearance: 14 },
              { name: "Innocent", image: "Innocent.png", appearance: 14 },
              { name: "Kawaii", image: "Kawaii.png", appearance: 8 },
              { name: "Sexy", image: "Sexy.png", appearance: 8 },
            ],
          },
          head: {
            trait_type: "Head",
            traits: [
              { name: "None", appearance: 35 },

              {
                name: "Duckie",
                image: "Duckie.png",
                appearance: 2,
              },
              {
                name: "Fries Cap",
                image: "Fries Cap.png",
                appearance: 3,
              },
              {
                name: "Blue Kitten",
                image: "Blue Kitten.png",
                appearance: 3.5,
              },
              {
                name: "Carrot (Circle)",
                image: "Carrot (Circle).png",
                appearance: 4,
              },
              { name: "Dojo Hat", image: "Dojo Hat.png", appearance: 4.5 },
              { name: "Green Cap", image: "Green Cap.png", appearance: 6.5 },
              {
                name: "Joker Crown",
                image: "Joker Crown.png",
                appearance: 0.5,
              },
              { name: "Leaf", image: "Leaf.png", appearance: 4 },
              {
                name: "Mini Crown (Circle)",
                image: "Mini Crown (Circle).png",
                appearance: 1,
              },
              { name: "Orange", image: "Orange.png", appearance: 2 },
              {
                name: "Pink Kitten",
                image: "Pink Kitten.png",
                appearance: 3.5,
              },
              {
                name: "Pirate Hat (Circle)",
                image: "Pirate Hat (Circle).png",
                appearance: 4.5,
              },
              { name: "Red Cloud", image: "Red Cloud.png", appearance: 3.5 },
              {
                name: "Salmon Roe (Circle)",
                image: "Salmon Roe (Circle).png",
                appearance: 1.5,
              },
              { name: "Warrior", image: "Warrior.png", appearance: 3 },
              { name: "Wasabi", image: "Wasabi.png", appearance: 3 },
              {
                name: "Yellow Bandana",
                image: "Yellow Bandana.png",
                appearance: 7,
              },
              { name: "Yellow Cap", image: "Yellow Cap.png", appearance: 8 },
            ],
          },
          weapon: {
            trait_type: "Weapon",
            traits: [
              { name: "None", image: "None.png", appearance: 70 },
              {
                name: "Wood Spatula (Circle)",
                image: "Wood Spatula (Circle).png",
                appearance: 3,
              },

              {
                name: "Gold Spatula (Circle)",
                image: "Gold Spatula (Circle).png",
                appearance: 0.5,
              },
              { name: "Blue Sword", image: "Blue Sword.png", appearance: 5 },
              {
                name: "Chain Dagger (Circle)",
                image: "Chain Dagger (Circle).png",
                appearance: 4,
              },
              {
                name: "Ebi Sword (Circle)",
                image: "Ebi Sword (Circle).png",
                appearance: 3,
              },
              {
                name: "Emerald Sword",
                image: "Emerald Sword.png",
                appearance: 0.5,
              },
              {
                name: "Glowing Sword",
                image: "Glowing Sword.png",
                appearance: 1,
              },
              {
                name: "Gold Shuriken (Circle)",
                image: "Gold Shuriken (Circle).png",
                appearance: 0.5,
              },
              {
                name: "Mini Dagger (Circle)",
                image: "Mini Dagger (Circle).png",
                appearance: 3.5,
              },
              { name: "Pink Sword", image: "Pink Sword.png", appearance: 5 },
              {
                name: "Shuriken (Circle)",
                image: "Shuriken (Circle).png",
                appearance: 4,
              },
            ],
          },
        },
      },
      {
        name: "Square",
        image: "Square Body.png",
        appearance: 40,
        subTraits: {
          back: {
            trait_type: "Back",
            traits: [
              { name: "None", appearance: 80 },
              {
                name: "Ajitama (Square)",
                image: "Ajitama (Square).png",
                appearance: 3,
              },
              {
                name: "Octopus (Square)",
                image: "Octopus (Square).png",
                appearance: 5,
              },

              {
                name: "Salmon (Square)",
                image: "Salmon (Square).png",
                appearance: 6,
              },
              {
                name: "Squid (Square)",
                image: "Squid (Square).png",
                appearance: 5,
              },
              {
                name: "Yellow Gourd",
                image: "Yellow Gourd.png",
                appearance: 1,
              },
            ],
          },
          clothes: {
            trait_type: "Clothes",
            traits: [
              { name: "None", appearance: 15 },
              {
                name: "Blue Belt (Square)",
                image: "Blue Belt (Square).png",
                appearance: 5,
              },
              {
                name: "Brown Red Belt (Square)",
                image: "Brown Red Belt (Square).png",
                appearance: 5,
              },
              {
                name: "Green Blue Belt (Square)",
                image: "Green Blue Belt (Square).png",
                appearance: 5,
              },
              {
                name: "Yellow Belt (Square)",
                image: "Yellow Belt (Square).png",
                appearance: 2,
              },
              {
                name: "Green Yellow Belt (Square)",
                image: "Green Yellow Belt (Square).png",
                appearance: 5,
              },
              {
                name: "Blue Red Belt (Square)",
                image: "Blue Red Belt (Square).png",
                appearance: 5,
              },
              {
                name: "Brown Belt (Square)",
                image: "Brown Belt (Square).png",
                appearance: 4.5,
              },
              {
                name: "Clown Outfit",
                image: "Clown Outfit.png",
                appearance: 1,
              },
              {
                name: "Joker Outfit",
                image: "Joker Outfit.png",
                appearance: 2,
              },
              { name: "Bee Float", image: "Bee Float.png", appearance: 2.5 },
              { name: "Beef Towel", image: "Beef Towel.png", appearance: 2.5 },
              { name: "Blue Tie", image: "Blue Tie.png", appearance: 3.5 },

              {
                name: "Dojo Master",
                image: "Dojo Master.png",
                appearance: 2.5,
              },
              {
                name: "Handheld Salmon (Square)",
                image: "Handheld Salmon (Square).png",
                appearance: 2.5,
              },
              {
                name: "Inari (Square)",
                image: "Inari (Square).png",
                appearance: 0.2,
              },
              {
                name: "Inari with Brown Belt (Square)",
                image: "Inari with Brown Belt (Square).png",
                appearance: 0.2,
              },
              {
                name: "Inari with Gourd",
                image: "Inari with Gourd.png",
                appearance: 0.2,
              },
              {
                name: "Inari with Long Brown Belt",
                image: "Inari with Long Brown Belt.png",
                appearance: 0.2,
              },
              {
                name: "Little Bandit",
                image: "Little Bandit.png",
                appearance: 4,
              },
              {
                name: "Long Brown Belt",
                image: "Long Brown Belt.png",
                appearance: 3,
              },
              {
                name: "Macho Apron",
                image: "Macho Apron.png",
                appearance: 3.5,
              },
              {
                name: "Mini Police",
                image: "Mini Police.png",
                appearance: 3.5,
              },
              { name: "Mongolian", image: "Mongolian.png", appearance: 4 },
              {
                name: "Red Dojo Outfit",
                image: "Red Dojo Outfit.png",
                appearance: 4,
              },
              { name: "Red Tie", image: "Red Tie.png", appearance: 3.5 },
              { name: "Rice Ball", image: "Rice Ball.png", appearance: 3 },
              {
                name: "Salmon Maki Outfit",
                image: "Salmon Maki Outfit.png",
                appearance: 0.2,
              },
              {
                name: "Striped Singlet",
                image: "Striped Singlet.png",
                appearance: 3.5,
              },
              {
                name: "Yellow Dojo Outfit",
                image: "Yellow Dojo Outfit.png",
                appearance: 4,
              },
            ],
          },
          face: {
            trait_type: "Face",
            traits: [
              { name: "Clown", image: "Clown.png", appearance: 2 },
              { name: "Captivated", image: "Captivated.png", appearance: 3 },
              { name: "Annoyed", image: "Annoyed.png", appearance: 6 },
              { name: "Bubbly", image: "Bubbly.png", appearance: 10 },
              { name: "Captain", image: "Captain.png", appearance: 7 },
              {
                name: "Cute Bubbly",
                image: "Cute Bubbly.png",
                appearance: 11,
              },
              { name: "Delicious", image: "Delicious.png", appearance: 9 },
              { name: "Duck Face", image: "Duck Face.png", appearance: 3 },
              { name: "Furious", image: "Furious.png", appearance: 8 },
              {
                name: "Glutton Angel",
                image: "Glutton Angel.png",
                appearance: 8,
              },
              { name: "Glutton", image: "Glutton.png", appearance: 8 },
              {
                name: "Honest Bear",
                image: "Honest Bear.png",
                appearance: 2,
              },
              { name: "Masked", image: "Masked.png", appearance: 1 },
              { name: "Pleased", image: "Pleased.png", appearance: 11 },
              { name: "Positive", image: "Positive.png", appearance: 11 },
            ],
          },
          head: {
            trait_type: "Head",
            traits: [
              { name: "None", appearance: 35 },
              {
                name: "Brown Fast Food Cap",
                image: "Brown Fast Food Cap.png",
                appearance: 2.5,
              },
              {
                name: "Black Fast Food Cap",
                image: "Black Fast Food Cap.png",
                appearance: 2.5,
              },
              {
                name: "Clown Hair",
                image: "Clown Hair.png",
                appearance: 1,
              },
              {
                name: "Polka Dot Hair",
                image: "Polka Dot Hair.png",
                appearance: 1,
              },
              { name: "Baby Hat", image: "Baby Hat.png", appearance: 3.5 },
              { name: "Blue Beret", image: "Blue Beret.png", appearance: 6.5 },
              { name: "Blue Cloud", image: "Blue Cloud.png", appearance: 4.5 },
              {
                name: "Blue Roe King",
                image: "Blue Roe King.png",
                appearance: 1,
              },
              {
                name: "Carrot (Square)",
                image: "Carrot (Square).png",
                appearance: 3,
              },
              {
                name: "Festive Hat",
                image: "Festive Hat.png",
                appearance: 4.5,
              },
              { name: "Fuji Hat", image: "Fuji Hat.png", appearance: 1.5 },
              { name: "Gold Crown", image: "Gold Crown.png", appearance: 0.2 },
              {
                name: "Gold Headband",
                image: "Gold Headband.png",
                appearance: 0.2,
              },
              {
                name: "Green Dojo Hat",
                image: "Green Dojo Hat.png",
                appearance: 6,
              },
              {
                name: "Khaki Beret",
                image: "Khaki Beret.png",
                appearance: 6.5,
              },
              {
                name: "Pink Bandana",
                image: "Pink Bandana.png",
                appearance: 7,
              },
              {
                name: "Pirate Hat (Square)",
                image: "Pirate Hat (Square).png",
                appearance: 5,
              },
              {
                name: "Red Roe King",
                image: "Red Roe King.png",
                appearance: 1,
              },
              {
                name: "Silver Crown",
                image: "Silver Crown.png",
                appearance: 0.6,
              },
              { name: "Yellow Hat", image: "Yellow Hat.png", appearance: 7 },
            ],
          },
          weapon: {
            trait_type: "Weapon",
            traits: [
              { name: "None", image: "None.png", appearance: 70 },
              {
                name: "Wood Spatula (Square)",
                image: "Wood Spatula (Square).png",
                appearance: 3,
              },

              {
                name: "Gold Spatula (Square)",
                image: "Gold Spatula (Square).png",
                appearance: 0.5,
              },
              { name: "Axe", image: "Axe.png", appearance: 3 },
              {
                name: "Chain Dagger (Square)",
                image: "Chain Dagger (Square).png",
                appearance: 3.5,
              },
              {
                name: "Diamond Sword",
                image: "Diamond Sword.png",
                appearance: 0.5,
              },
              {
                name: "Ebi Sword (Square)",
                image: "Ebi Sword (Square).png",
                appearance: 2.5,
              },
              {
                name: "Gold Nyoibo",
                image: "Gold Nyoibo.png",
                appearance: 0.5,
              },
              {
                name: "Gold Shuriken (Square)",
                image: "Gold Shuriken (Square).png",
                appearance: 0.5,
              },
              {
                name: "Mini Dagger (Square)",
                image: "Mini Dagger (Square).png",
                appearance: 3.5,
              },

              { name: "Red Sword", image: "Red Sword.png", appearance: 4.5 },
              {
                name: "Shuriken (Square)",
                image: "Shuriken (Square).png",
                appearance: 3,
              },
              {
                name: "Yellow Sword",
                image: "Yellow Sword.png",
                appearance: 5,
              },
            ],
          },
        },
      },
      {
        name: "Triangle",
        image: "Triangle Body.png",
        appearance: 35,
        subTraits: {
          back: {
            trait_type: "Back",
            traits: [
              { name: "None", appearance: 80 },
              {
                name: "Ajitama (Triangle)",
                image: "Ajitama (Triangle).png",
                appearance: 2.5,
              },
              {
                name: "Crab Claw (Triangle)",
                image: "Crab Claw (Triangle).png",
                appearance: 2,
              },
              {
                name: "Octopus (Triangle)",
                image: "Octopus (Triangle).png",
                appearance: 4,
              },
              { name: "Raw Crab", image: "Raw Crab.png", appearance: 3 },
              { name: "Red Gourd", image: "Red Gourd.png", appearance: 0.5 },
              {
                name: "Salmon (Triangle)",
                image: "Salmon (Triangle).png",
                appearance: 3,
              },
              {
                name: "Squid (Triangle)",
                image: "Squid (Triangle).png",
                appearance: 4,
              },
              { name: "Tamago", image: "Tamago.png", appearance: 1 },
            ],
          },
          clothes: {
            trait_type: "Clothes",
            traits: [
              { name: "None", appearance: 15 },
              {
                name: "Brown Red Belt (Triangle)",
                image: "Brown Red Belt (Triangle).png",
                appearance: 4.5,
              },
              {
                name: "Yellow Belt (Triangle)",
                image: "Yellow Belt (Triangle).png",
                appearance: 5.5,
              },
              {
                name: "Blue Green Belt (Triangle)",
                image: "Blue Green Belt (Triangle).png",
                appearance: 5.5,
              },
              {
                name: "Yellow Green Belt (Triangle)",
                image: "Yellow Green Belt (Triangle).png",
                appearance: 5.5,
              },
              {
                name: "Blue Red Belt (Triangle)",
                image: "Blue Red Belt (Triangle).png",
                appearance: 4.5,
              },
              {
                name: "Brown Belt (Triangle)",
                image: "Brown Belt (Triangle).png",
                appearance: 4.8,
              },
              {
                name: "Fast Food Apron",
                image: "Fast Food Apron.png",
                appearance: 1.5,
              },
              { name: "Apron", image: "Apron.png", appearance: 2 },
              { name: "Beef Belt", image: "Beef Belt.png", appearance: 2.5 },
              {
                name: "Blue Fighter",
                image: "Blue Fighter.png",
                appearance: 4.5,
              },
              {
                name: "Ceremonial Belt",
                image: "Ceremonial Belt.png",
                appearance: 3.5,
              },
              {
                name: "Ceremonial Oufit",
                image: "Ceremonial Oufit.png",
                appearance: 2.5,
              },
              {
                name: "Festive Belt",
                image: "Festive Belt.png",
                appearance: 3.5,
              },
              {
                name: "Gold Merchant",
                image: "Gold Merchant.png",
                appearance: 0.8,
              },
              {
                name: "Handheld Salmon (Triangle)",
                image: "Handheld Salmon (Triangle).png",
                appearance: 2,
              },
              {
                name: "Inari with Brown Belt (Triangle)",
                image: "Inari with Brown Belt (Triangle).png",
                appearance: 0.2,
              },
              {
                name: "Jade Warrior",
                image: "Jade Warrior.png",
                appearance: 1.5,
              },
              {
                name: "Legacy Warrior",
                image: "Legacy Warrior.png",
                appearance: 2,
              },
              { name: "Ninja", image: "Ninja.png", appearance: 4 },
              {
                name: "Red Fighter",
                image: "Red Fighter.png",
                appearance: 4.5,
              },
              { name: "Red Mino", image: "Red Mino.png", appearance: 4.5 },
              {
                name: "Royal Warrior",
                image: "Royal Warrior.png",
                appearance: 3.5,
              },
              {
                name: "Salmon Scarf",
                image: "Salmon Scarf.png",
                appearance: 2.5,
              },
              {
                name: "Silver Merchant",
                image: "Silver Merchant.png",
                appearance: 1.2,
              },
              {
                name: "Tempura Scarf",
                image: "Tempura Scarf.png",
                appearance: 2,
              },
              {
                name: "Traditional Outfit",
                image: "Traditional Outfit.png",
                appearance: 3.5,
              },
              {
                name: "Warrior Belt",
                image: "Warrior Belt.png",
                appearance: 2.5,
              },
            ],
          },
          face: {
            trait_type: "Face",
            traits: [
              { name: "Piggy", image: "Piggy.png", appearance: 5 },
              { name: "Cheering", image: "Cheering.png", appearance: 11 },
              {
                name: "Comfortable",
                image: "Comfortable.png",
                appearance: 10,
              },
              { name: "Confident", image: "Confident.png", appearance: 11 },
              { name: "Expectant", image: "Expectant.png", appearance: 11 },
              {
                name: "Inquisitive",
                image: "Inquisitive.png",
                appearance: 10,
              },
              {
                name: "Monster King",
                image: "Monster King.png",
                appearance: 5,
              },
              { name: "Pepe", image: "Pepe.png", appearance: 3 },
              { name: "Serious", image: "Serious.png", appearance: 10 },
              { name: "Speechless", image: "Speechless.png", appearance: 11 },
              { name: "Striving", image: "Striving.png", appearance: 11 },
              { name: "Wow Wow", image: "Wow Wow.png", appearance: 2 },
            ],
          },
          head: {
            trait_type: "Head",
            traits: [
              { name: "None", appearance: 35 },
              {
                name: "Fries Hat",
                image: "Fries Hat.png",
                appearance: 2.5,
              },
              {
                name: "Black Ponytail",
                image: "Black Ponytail.png",
                appearance: 3,
              },
              {
                name: "Blue Furry Beanie",
                image: "Blue Furry Beanie.png",
                appearance: 5.5,
              },
              {
                name: "Carrot (Triangle)",
                image: "Carrot (Triangle).png",
                appearance: 3,
              },
              {
                name: "Classic Ponytail",
                image: "Classic Ponytail.png",
                appearance: 4.5,
              },
              {
                name: "Duckface Hat",
                image: "Duckface Hat.png",
                appearance: 1,
              },
              { name: "Gold Tiara", image: "Gold Tiara.png", appearance: 0.2 },
              {
                name: "Green Bandana",
                image: "Green Bandana.png",
                appearance: 6.5,
              },
              { name: "Knight", image: "Knight.png", appearance: 2 },
              {
                name: "Mini Crown (Triangle)",
                image: "Mini Crown (Triangle).png",
                appearance: 0.2,
              },
              {
                name: "Mint Leaf",
                image: "Mint Leaf.png",
                appearance: 4.5,
              },
              {
                name: "Orange Beanie",
                image: "Orange Beanie.png",
                appearance: 7.5,
              },
              {
                name: "Pirate Hat (Triangle)",
                image: "Pirate Hat (Triangle).png",
                appearance: 5,
              },
              {
                name: "Pomegranate",
                image: "Pomegranate.png",
                appearance: 2.5,
              },
              {
                name: "Red Furry Beanie",
                image: "Red Furry Beanie.png",
                appearance: 6.5,
              },
              {
                name: "Salmon Roe (Triangle)",
                image: "Salmon Roe (Triangle).png",
                appearance: 1.1,
              },
              {
                name: "Samurai Hat",
                image: "Samurai Hat.png",
                appearance: 4.5,
              },
              {
                name: "Silver Tiara",
                image: "Silver Tiara.png",
                appearance: 0.5,
              },
              {
                name: "Wealthy Pirate Hat",
                image: "Wealthy Pirate Hat.png",
                appearance: 1.5,
              },
              {
                name: "White Ponytail",
                image: "White Ponytail.png",
                appearance: 3,
              },
            ],
          },
          weapon: {
            trait_type: "Weapon",
            traits: [
              { name: "None", image: "None.png", appearance: 70 },
              {
                name: "Wood Spatula (Triangle)",
                image: "Wood Spatula (Triangle).png",
                appearance: 3,
              },

              {
                name: "Gold Spatula (Triangle)",
                image: "Gold Spatula (Triangle).png",
                appearance: 0.5,
              },
              {
                name: "Chain Dagger (Triangle)",
                image: "Chain Dagger (Triangle).png",
                appearance: 3.5,
              },
              { name: "Dagger", image: "Dagger.png", appearance: 4.5 },
              {
                name: "Ebi Sword (Triangle)",
                image: "Ebi Sword (Triangle).png",
                appearance: 2.5,
              },
              {
                name: "Gold Shuriken (Triangle)",
                image: "Gold Shuriken (Triangle).png",
                appearance: 0.5,
              },
              {
                name: "Green Sword",
                image: "Green Sword.png",
                appearance: 5.5,
              },
              {
                name: "Red Balloon",
                image: "Red Balloon.png",
                appearance: 3,
              },
              {
                name: "Shuriken (Triangle)",
                image: "Shuriken (Triangle).png",
                appearance: 4,
              },
              {
                name: "Yellow Balloon",
                image: "Yellow Balloon.png",
                appearance: 3,
              },
            ],
          },
        },
      },
    ],
  },
};

export function getSushiPoints({
  id,
  tokenId,
  rank,
  startTime,
  endTime,
}: {
  id: number;
  tokenId: number;
  rank: number;
  startTime: number;
  endTime: number;
}) {
  const dateNow = new Date().getTime();

  const totalTimeDiff = endTime - startTime;
  let multiplier = 1;
  // 7 days = 604800000
  if (totalTimeDiff >= 63072000000) {
    // 2 years = 63072000000
    multiplier = 2.8;
  } else if (totalTimeDiff >= 31536000000) {
    // 365 days = 31536000000
    multiplier = 2.4;
  } else if (totalTimeDiff >= 15552000000) {
    // 180 days = 15552000000
    multiplier = 2;
  } else if (totalTimeDiff >= 10368000000) {
    // 120 days = 10368000000
    multiplier = 1.8;
  } else if (totalTimeDiff >= 5184000000) {
    // 60 days = 5184000000
    multiplier = 1.6;
  } else if (totalTimeDiff >= 2592000000) {
    // 30 days = 2592000000
    multiplier = 1.4;
  } else if (totalTimeDiff >= 1209600000) {
    // 14 days =  1209600000
    multiplier = 1.2;
  }

  let currentTimeDiff = dateNow - startTime;
  if (dateNow < startTime) {
    currentTimeDiff = 0;
  } else if (dateNow > endTime) {
    currentTimeDiff = endTime - startTime;
  }

  const score = (18000 - rank) / 3;

  const timeDivisor = 0.0000000005;

  const accruedPoints = score * multiplier * (currentTimeDiff * timeDivisor);
  const endOfStakePoints = score * multiplier * (totalTimeDiff * timeDivisor);
  const pointsPerDay = score * multiplier * (86400000 * timeDivisor);
  const data = {
    id: id,
    tokenId: tokenId,
    startTime: startTime,
    endTime: endTime,
    currentTimeDiff,
    totalTimeDiff,
    multiplier,
    rank,
    // score,
    accruedPoints,
    endOfStakePoints,
    pointsPerDay,
  };

  return data;
}
