import { StateCreator } from "zustand";
import { Cart, CartSlice } from "store/interfaces/cart.interface";

export const cartSlice: StateCreator<CartSlice, [], [], CartSlice> = (set) => ({
  cartData: [],
  setCartData: (data: Cart[]) =>
    set((s) => ({
      ...s,
      cartData: data,
    })),
});
