import useStore from "../store";
import { multicallFetcher } from "utils/multicallFetcher";
import useNetwork from "hooks/useNetwork";
import useURL from "hooks/useURL";
import { toBase64 } from "@injectivelabs/sdk-ts";
import useNftApi from "rest/useNftApi.ts";
import { NFT_TOKEN_ADDRESS } from "constants/constants";
import useAllNftStore from "../store/allNftsStore";

export const useNft = () => {
  const allNfts = useAllNftStore((s) => s.allNfts);
  const setAllNfts = useAllNftStore((s) => s.setAllNfts);
  const setMyNftData = useStore((s) => s.setMyNftData);
  const setNftWallet = useStore((s) => s.setNftWallet);
  const setIsNftLoading = useStore((s) => s.setIsNftLoading);
  const { multicall } = useNetwork();
  const getURL = useURL();
  const { api } = useNftApi();

  const fetchAllNfts = async () => {
    try {
      const _allNfts = await api.nft.findAll();
      setAllNfts(_allNfts);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchNft = async (walletAddress) => {
    setIsNftLoading(true);
    try {
      const fetcher = multicallFetcher(multicall, getURL);

      let queries = [
        {
          address: NFT_TOKEN_ADDRESS,
          data: toBase64({ token_info: {} }),
        },
        {
          address: NFT_TOKEN_ADDRESS,
          data: toBase64({ num_tokens: {} }),
        },
      ];

      if (walletAddress !== "") {
        queries.push({
          address: NFT_TOKEN_ADDRESS,
          data: toBase64({ user_info: { address: walletAddress } }),
        });
      }

      const resp = await fetcher([queries]);

      const collectResults = (resp ?? []).reduce(
        (acc, curr) => acc.concat(curr.data.data.return_data),
        []
      );

      const nftResults = collectResults?.map((e: any) => {
        return e.length == 0
          ? null
          : JSON.parse(Buffer.from(e.data, "base64").toString());
      });

      const tokenIds =
        nftResults?.[2]?.owned?.map((item) => parseInt(item)) ?? [];

      const lockNftQueries =
        tokenIds?.length > 0
          ? tokenIds?.map((tokenId) => ({
              address: NFT_TOKEN_ADDRESS,
              data: toBase64({ is_locked: { token_id: `${tokenId}` } }),
            }))
          : [];

      const rawLockResults = await fetcher([lockNftQueries]);
      const lockResults = (rawLockResults ?? []).reduce(
        (acc, curr) => acc.concat(curr.data.data.return_data),
        []
      );

      const lock = lockResults?.map((e: any, index) => {
        return e.length == 0
          ? null
          : {
              tokenId: tokenIds[index],
              isLocked: JSON.parse(Buffer.from(e.data, "base64").toString()),
            };
      });

      const stakeNftQueries =
        tokenIds?.length > 0
          ? tokenIds?.map((tokenId) => ({
              address: NFT_TOKEN_ADDRESS,
              data: toBase64({ staked_till: { token_id: `${tokenId}` } }),
            }))
          : [];

      const rawStakeResults = await fetcher([stakeNftQueries]);
      const stakeResults = (rawStakeResults ?? []).reduce(
        (acc, curr) => acc.concat(curr.data.data.return_data),
        []
      );

      const stake = stakeResults?.map((e: any, index) => {
        return e.length == 0
          ? null
          : {
              tokenId: tokenIds[index],
              stakedTill: JSON.parse(Buffer.from(e.data, "base64").toString()),
            };
      });

      const result = {
        info: nftResults?.[0],
        totalMinted: nftResults?.[1]?.count,
        userBalance: nftResults?.[2]?.balances,
        userTokenIds: tokenIds,
        nextToBurnTokenId:
          tokenIds?.length > 0 ? tokenIds[tokenIds?.length - 1] : 0,
        myNfts:
          allNfts === undefined
            ? undefined
            : tokenIds?.map((item) => {
                const foundNft = allNfts?.find((x) => x.index === item);
                if (foundNft) {
                  return {
                    ...foundNft,
                    isLocked: lock?.find((i) => i?.tokenId === foundNft?.index)
                      ?.isLocked,
                    stakedTill:
                      stake?.find((i) => i?.tokenId === foundNft?.index)
                        ?.stakedTill ?? 0,
                    isProcessing: false,
                    is404: foundNft?.metadata?.attributes?.some(
                      (attr) => attr?.value === "404"
                    ),
                  };
                } else {
                  return {
                    index: item,
                    metadata: {
                      name: `Sushi Fighter #${item}`,
                      image: "https://d2no7rxs0v7peg.cloudfront.net/404.avif",
                      tokenId: item,
                      attributes: [
                        {
                          value: "404",
                          trait_type: "Background",
                        },
                        {
                          value: "404",
                          trait_type: "Back",
                        },
                        {
                          value: "404",
                          trait_type: "Body Shape",
                        },
                        {
                          value: "404",
                          trait_type: "Clothes",
                        },
                        {
                          value: "404",
                          trait_type: "Weapon",
                        },
                        {
                          value: "404",
                          trait_type: "Face",
                        },
                        {
                          value: "404",
                          trait_type: "Head",
                        },
                        {
                          value: "404",
                          trait_type: "Overlays",
                        },
                      ],
                      description:
                        "Enter the extraordinary realm of the Sushi Fighter, where each grain of rice contains the perfect balance of undeniable cuteness and unfaltering deadliness. Come along our epic journey where you will discover that true strength lies not just in power, but in the unwavering courage to face the unknown, together as one cohesive community.",
                    },
                    isProcessing: true,
                  };
                }
              }),
      };
      setNftWallet(walletAddress);
      setMyNftData(result);
    } catch (e) {
      console.error(e);
    } finally {
      setIsNftLoading(false);
    }
  };

  return { fetchAllNfts, fetchNft };
};
