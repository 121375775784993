import { create, StoreApi, UseBoundStore } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
// import { devtools } from "zustand/middleware"

import { Store } from "./interfaces/store.interface";
import { txStateSlice } from "./slices/txStateSlice";
import { walletSlice } from "./slices/walletSlice";
import { myNftDataSlice } from "./slices/myNftDataSlice";
import { farmsSlice } from "./slices/farmsSlice";
import { cartSlice } from "./slices/cartSlice";

let useStore: UseBoundStore<StoreApi<Store>>;

useStore = create<Store>()(
  persist(
    (...a) => ({
      ...txStateSlice(...a),
      ...walletSlice(...a),
      ...farmsSlice(...a),
      ...cartSlice(...a),
      ...myNftDataSlice(...a),
    }),
    {
      name: "sushifighter1", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !["allNfts"].includes(key))
        ),
    }
  )
);

export default useStore;
