import { Link, useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { motion, useCycle } from "framer-motion";
import { useRef, useState } from "react";
import { Pivot as Hamburger } from "hamburger-react";
import HeaderConnect from "./HeaderConnect";
import useDimensions from "utils/useDimensions";
import HeaderConnectMobile from "./HeaderConnectMobile";
import { IS_DEVELOPMENT } from "libs/services";
import BuyModal from "./BuyModal";
import PriceChartModal from "./PriceChartModal";
import usePriceStore from "../store/priceStore";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, toggleNav] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const isHome = pathname === "/";
  const isWhiteLogo = isHome && !isOpen;
  const [showPriceChartModal, setShowPriceChartModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const pricesBySymbol = usePriceStore((s) => s.pricesBySymbol);

  const isActive = (_pathname: string) => {
    if (_pathname === "/") {
      return pathname === "/";
    }
    if (pathname.includes(_pathname)) {
      return true;
    }
    return false;
  };

  const sidebar = {
    open: {
      transition: {
        type: "spring",
        duration: 0.75,
      },
      opacity: 1,
    },
    closed: {
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
      opacity: 0,
    },
  };

  return (
    <>
      <header className="fixed w-full top-0 px-4 lg:px-8 lg:pt-8 pt-5 z-30 opacity-100 pointer-events-auto duration-200 max-w-full z-[999]">
        <div className="flex h-full border-b border-white items-center justify-between max-w-11xl mx-auto relative z-10 border-opacity-0">
          <div className="shrink-0 mr-2">
            <div className="flex relative">
              <Link
                to="/"
                className={classNames(
                  "w-min-content relative",
                  isHome && !isOpen ? "hidden sm:block" : ""
                )}
              >
                <img
                  src="/images/logo.svg"
                  className={classNames(
                    "h-8 w-auto py-2 px-3 rounded-lg bg-black transition-opacity duration-30",
                    isWhiteLogo ? "visible opacity-100" : "invisible opacity-0"
                  )}
                />
                <img
                  src="/images/logo-black.svg"
                  className={classNames(
                    "h-full w-auto py-2 px-3 rounded-lg bg-white transition-opacity duration-300 absolute top-0 left-0",
                    !isWhiteLogo ? "visible opacity-100" : "invisible opacity-0"
                  )}
                />
              </Link>
              {IS_DEVELOPMENT && (
                <div
                  className={classNames(
                    "rounded-[5px] text-[9px] font-bold leading-none py-[2px] px-[6px] text-black bg-secondary",
                    isHome && !isOpen
                      ? "sm:absolute sm:bottom-[-10px] sm:right-[-4px]"
                      : "absolute bottom-[-10px] right-[-4px]"
                    // "shadow-[0_0_15px_#00f2ff]"
                  )}
                >
                  TESTNET
                </div>
              )}
            </div>
          </div>

          <div className="hidden lg:flex">
            <div className="flex items-center text-white gap-x-3 text-sm">
              <Link
                to="/about"
                className={classNames(
                  "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white backdrop-blur-sm",
                  isActive("/about")
                    ? "bg-opacity-5 cursor-default"
                    : "bg-opacity-20 hover:bg-opacity-30"
                )}
              >
                ABOUT
              </Link>
              <div
                onClick={() => setShowBuyModal(true)}
                className={classNames(
                  "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white backdrop-blur-sm bg-opacity-20 hover:bg-opacity-30 cursor-pointer"
                )}
              >
                BUY
              </div>
              <Link
                to="/gallery"
                className={classNames(
                  "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white backdrop-blur-sm",
                  isActive("/gallery")
                    ? "bg-opacity-5 cursor-default"
                    : "bg-opacity-20 hover:bg-opacity-30"
                )}
              >
                GALLERY
              </Link>
              <Link
                to="/my-nfts"
                className={classNames(
                  "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white backdrop-blur-sm",
                  isActive("/my-nfts")
                    ? "bg-opacity-5 cursor-default"
                    : "bg-opacity-20 hover:bg-opacity-30"
                )}
              >
                MY NFTs
              </Link>
              <Link
                to="/analytics"
                className={classNames(
                  "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white backdrop-blur-sm",
                  isActive("/analytics")
                    ? "bg-opacity-5 cursor-default"
                    : "bg-opacity-20 hover:bg-opacity-30"
                )}
              >
                ANALYTICS
              </Link>

              <a
                href="https://x.com/sushifighternft"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white bg-opacity-20 hover:bg-opacity-30 backdrop-blur-sm"
              >
                <img src="/images/twitter.svg" className="h-3.5 w-auto" />
              </a>
              <a
                href="https://t.me/SushiFighterNFT/"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white bg-opacity-20 hover:bg-opacity-30 backdrop-blur-sm"
              >
                <img src="/images/telegram.svg" className="h-3.5 w-auto" />
              </a>
              <div
                onClick={() => setShowPriceChartModal(true)}
                className={classNames(
                  "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-2 bg-white backdrop-blur-sm bg-opacity-20 hover:bg-opacity-30 cursor-pointer"
                )}
              >
                <img
                  src={`/images/tokens/sushi.webp`}
                  alt="sushi"
                  className="h-3.5 w-auto mr-2"
                />
                {pricesBySymbol?.["SUSHI"]
                  ? pricesBySymbol?.["SUSHI"]?.toFixed(2)
                  : "-"}
              </div>
              <HeaderConnect />
            </div>
          </div>
          <div className="flex lg:hidden -mr-[14px] items-center gap-x-2">
            <div
              onClick={() => setShowPriceChartModal(true)}
              className={classNames(
                "font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-2 bg-black backdrop-blur-sm bg-opacity-20 hover:bg-opacity-30 cursor-pointer"
              )}
            >
              <img
                src={`/images/tokens/sushi.webp`}
                alt="sushi"
                className="h-3.5 w-auto mr-2"
              />
              {pricesBySymbol?.["SUSHI"]
                ? pricesBySymbol?.["SUSHI"]?.toFixed(2)
                : ""}
            </div>
            <Hamburger
              toggled={isOpen}
              toggle={() => toggleNav()}
              size={20}
              color="#ffffff"
              duration={0.3}
            />
          </div>
        </div>
        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          className={classNames("lg:hidden", isOpen ? "" : "hidden")}
        >
          <motion.div
            ref={containerRef}
            className="h-full w-full fixed top-0 right-0 bg-black"
            variants={sidebar}
            custom={height}
          >
            <div className="w-full overflow-y-auto px-4 pt-5 h-full w-full">
              <div className="mt-20">
                <div className="-my-6 divide-y divide-white/20">
                  <div className="space-y-2 py-6">
                    <div
                      onClick={() => {
                        navigate("/about");
                        toggleNav();
                      }}
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer"
                    >
                      About
                    </div>
                    <div
                      onClick={() => {
                        navigate("/gallery");
                        toggleNav();
                      }}
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer"
                    >
                      Gallery
                    </div>
                    <div
                      onClick={() => {
                        setShowBuyModal(true);
                        toggleNav();
                      }}
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer"
                    >
                      Buy
                    </div>
                    <div
                      onClick={() => {
                        navigate("/my-nfts");
                        toggleNav();
                      }}
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer"
                    >
                      My NFTs
                    </div>
                    <div
                      onClick={() => {
                        navigate("/analytics");
                        toggleNav();
                      }}
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer"
                    >
                      Analytics
                    </div>
                    <a
                      href="https://x.com/sushifighternft"
                      target="_blank"
                      rel="noreferrer"
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between"
                    >
                      <span className="block">TWITTER</span>
                      <img
                        src="/images/twitter.svg"
                        className="h-4 w-auto block"
                      />
                    </a>
                    <a
                      href="https://t.me/SushiFighterNFT/"
                      target="_blank"
                      rel="noreferrer"
                      className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between"
                    >
                      <span className="block">TELEGRAM</span>
                      <img
                        src="/images/telegram.svg"
                        className="h-4 w-auto block"
                      />
                    </a>
                    {/* <a
                  href="https://medium.com/@dojoswap/%EF%B8%8F-the-first-cw404-on-injective-by-dojoswap-b56930def784"
                  target="_blank"
                  rel="noreferrer"
                  className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between">
                  <span className="block">MEDIUM</span>
                  <img
                    src="/images/medium.svg"
                    className="h-4 w-auto block"
                  />
                </a> */}
                  </div>
                  <div className="py-6">
                    <HeaderConnectMobile />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.nav>
      </header>
      <PriceChartModal
        open={showPriceChartModal}
        onClose={() => setShowPriceChartModal(false)}
      />
      <BuyModal open={showBuyModal} onClose={() => setShowBuyModal(false)} />
    </>
  );
};

export default Header;
