export default function createTimeout(delay) {
  let timeoutId;
  const promise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error('Request timed out after ' + delay + ' seconds'));
    }, delay * 1000);
  });

  return {
    promise,
    cancel: () => clearTimeout(timeoutId),
  };
}


