import { NETWORK } from "libs/services"
import { Network } from "@injectivelabs/networks"
import {
  ATOM,
  BITS,
  DOJO,
  FROG,
  FarmToken,
  GINGER,
  INJ,
  KAGE,
  KIRA,
  NINJA,
  PUNK,
  ROLL,
  SOL,
  SUSHI,
  TIA,
  TokenType,
  USDC,
  USDT,
  WBTC,
  WETH,
  dINJ,
  hINJ,
  serializeToken,
} from "./tokens"

export interface FarmConfig {
  name: string
  lpAddress: string
  contractAddress: string
  stakingAddress: string
  token0: FarmToken
  token1: FarmToken
  hidden?: boolean
}

export interface PublicFarmData extends FarmConfig {
  vault: {
    config: {
      distribution_schedule: [number, number, string][]
    }
    stakedBalance: string
    walletBalance: string
    staker: {
      bond_amount: string
      pending_reward: string
      reward_index: string
    }
    state: {
      global_reward_index: string
      last_distributed: number
      total_bond_amount: string
    }
    lpInfo: {
      total_supply: string
      token0Supply: string
      token1Supply: string
    }
    total_rewards: string
  }
}

export const farmsV2: { mainnet: FarmConfig[]; testnet: FarmConfig[] } = {
  mainnet: [
    {
      name: "SUSHI-DOJO LP",
      lpAddress: "inj1500pn2xwlczkms8h8ggy5ptv9etyu5yxx45x70",
      contractAddress: "inj12eca9xszt84qm9tztyuje96nn3v2wd3v4yrzge",
      stakingAddress: "inj1yqtcds4gpvhcdlpjh9u45xjx9lxwame7fa265x",
      token0: SUSHI,
      token1: DOJO,
    },
    {
      name: "DOJO-INJ LP",
      lpAddress: "inj17pda96ujt7fzr3d5jmfkh4dzvrqzc0nk56kt34",
      contractAddress: "inj1grtkdl7552kjsrkqn5wqpk4fp8m3m4y0tzqfqr",
      stakingAddress: "inj19rutrad95wzcw93gfnuranetmc570cvtj8j8cg",
      token0: DOJO,
      token1: INJ,
    },
    {
      name: "DOJO-dINJ LP",
      lpAddress: "inj1lugaw82rk02ckm3zeu24su0p0r853sagn9qkaa",
      contractAddress: "inj1wpdh2qmq0lzjnyvae8p94g892mcq2jmpc984qp",
      stakingAddress: "inj1ycnddgnj49lntk3z5ky8pj0rpvhkvggmyjsmv7",
      token0: dINJ,
      token1: DOJO,
    },
    {
      name: "dINJ-INJ LP",
      lpAddress: "inj1rl004yqz80hdkn5ctnqfxngv24cs2yc39zqaxt",
      contractAddress: "inj1apnwjz58gn96ypw9amkph2s9z0ux8df484yp04",
      stakingAddress: "inj1uhwr3n0guelz4xv5y65h9tgxr4saxhmq4057ur",
      token0: dINJ,
      token1: INJ,
    },
    {
      name: "hINJ-dINJ LP",
      lpAddress: "inj10hyl7t5zxrs83thk33yk90lue34lttf9wuccf8",
      contractAddress: "inj17294j867ul0t7wprlajw03tkuryfekws9d228j",
      stakingAddress: "inj1mjpns8l7860ppnd7rclwejvvgfmqyrn6u8klwy",
      token0: dINJ,
      token1: hINJ,
    },
    {
      name: "FROG-INJ LP",
      lpAddress: "inj180zwmntk4yw8mp90qzedvgg63lc8g9ju4f79pn",
      contractAddress: "inj1scjlghhefm40zyvhmuccd6qqf0qtvfpsztq53r",
      stakingAddress: "inj187ngvjvh99nk7cwlmtkwhyqcxt59vnr43nsjnk",
      token0: FROG,
      token1: INJ,
    },
    {
      name: "BITS-INJ LP",
      lpAddress: "inj1a8mlar2l3r25uaxdgk0zxyapq7h0435264h8jk",
      contractAddress: "inj16vfpvvrh4fp3yanrwqxn58s8ea79qftajp5rl0",
      stakingAddress: "inj1kug47pz74u8jzk8565hzdxckn6npvpusyqf0uh",
      token0: BITS,
      token1: INJ,
    },
    {
      name: "PUNK-DOJO LP",
      lpAddress: "inj1c43k77927yryd8wkzupnppen83alqkm5myt6we",
      contractAddress: "inj1safvc662qz4nz9zrn6ydc7a4zf3dp6wt38p4gg",
      stakingAddress: "inj1munselhfgsdss6y34d2rwcu5njxj4spts46hpq",
      token0: DOJO,
      token1: PUNK,
    },
    {
      name: "INJ-USDT LP",
      lpAddress: "inj15xk5d4d3we8z9s9avcqfns2xsrqq9u5mgaw6q6",
      contractAddress: "inj1h0mpv48ctcsmydymh2hnkal7hla5gl4gftemqv",
      stakingAddress: "inj1fjj6srxtqel77svq3qpx53vxf8nl8q296a6gq2",
      token0: INJ,
      token1: USDT,
    },
    {
      name: "KAGE-INJ LP",
      lpAddress: "inj1d3rkrv8f4xm4zv5rcdkydy36xzdksq94ewy5md",
      contractAddress: "inj1er0535pz8k3l6wpzm263vv9gmkghwct7he85pl",
      stakingAddress: "inj17vrlsuxqs89800ccth5krzffs0f5zq4k5kysa7",
      token0: KAGE,
      token1: INJ,
    },
    {
      name: "DOJO-ROLL LP",
      lpAddress: "inj17m8xuqntg5sc3m78jzajte5r4my4erwhujq3uh",
      contractAddress: "inj10eqj5s4ln2a9tt2jrfrh3s6sucgh0lq9hu820c",
      stakingAddress: "inj1zmqyp2rh92t8vsgr6z4jmgahddufgpk8za00vu",
      token0: ROLL,
      token1: DOJO,
    },
    {
      name: "NINJA-INJ LP",
      lpAddress: "inj1q62knrccf8n2386jzzv2plr6rat2lfvx95muqv",
      contractAddress: "inj1efdrt4s78gffnntlqlhcctu3h0ndsd0nr86edc",
      stakingAddress: "inj1s0dxe2nj3q08u03mqptv4mqakyv598ddf3wcsk",
      token0: NINJA,
      token1: INJ,
    },
    {
      name: "KIRA-INJ LP",
      lpAddress: "inj1nssppa309t2pty5agwrmrjqx9md9764ntesce6",
      contractAddress: "inj1eswdzx773we5zu2mz0zcmm7l5msr8wcss8ek0f",
      stakingAddress: "inj1myqk5sfchpsu6aha6jq765d73u4aze9fnw2akp",
      token0: KIRA,
      token1: INJ,
    },
    {
      name: "GINGER-INJ LP",
      lpAddress: "inj1an95wrjrhwyw2qteel0j0pllh9tvjh0qlcm98u",
      contractAddress: "inj1uugfny4cqxq0jg9vd6svyunhmdlk2s6wjdftj8",
      stakingAddress: "inj1ccynk2ql26nk4phzu20dw22v4at59k5nqaj4ax",
      token0: GINGER,
      token1: INJ,
    },
    {
      name: "ATOM-INJ LP",
      lpAddress: "inj1e4r4gcx3z4f2cey2wyy27zcnr8tvjs0zrdvtvs",
      contractAddress: "inj194zp3wnyd48cvlpa2nudq4w349a2cwsk2rug7p",
      stakingAddress: "inj1gt02s079590edge22gj7p8pw6r3vdz8thzevjj",
      token0: ATOM,
      token1: INJ,
    },
    {
      name: "SOL-INJ LP",
      lpAddress: "inj1vr057m6msmv47zv35mfcmqswsg7gj0rmr36ghd",
      contractAddress: "inj1nv6c9yu3nw7jw3d40ckr6fp6kp6j82edcf3m36",
      stakingAddress: "inj162uhdxlu9utrz2jhvx3yv629hg8m6uqwf28vh6",
      token0: SOL,
      token1: INJ,
    },
    {
      name: "TIA-INJ LP",
      lpAddress: "inj1cghvjzl7qp46r2e7sw9x2m2gsn8qt347wts9lh",
      contractAddress: "inj1vqe9v9mkq9t8f09jh94k9k4lhajrd9q4fdp3nz",
      stakingAddress: "inj1zpzdvgx474hyx8pzylzvtpdcku03upnpvk5qy5",
      token0: TIA,
      token1: INJ,
    },
    {
      name: "WBTC-INJ LP",
      lpAddress: "inj1ytl5y7plqak300e42akc3pzn2j9hp35lw2pv3k",
      contractAddress: "inj1zcmmxmxdw72s0xur2r2h6dfdzs6akl7n08twdp",
      stakingAddress: "inj1zd0z5z5qtrt0gcvty45g09w4k4yp3yj64gd52w",
      token1: INJ,
      token0: WBTC,
    },
    {
      name: "WETH-INJ LP",
      lpAddress: "inj1jqllyf6ta8z4l8whxj3jgjyfmucvpfl7tp4h3v",
      contractAddress: "inj18sf663n6zlh6unevsdv4vrfeqdkc0ze2ktu3aj",
      stakingAddress: "inj1cxdt4ewcdvjz26gqv5mc09qkcer93ypr6v4z9j",
      token0: WETH,
      token1: INJ,
    },
    {
      name: "USDT-USDC LP",
      lpAddress: "inj1w9z5t04v2jl6r85s8e0984f4v2drdpq8fnl6hs",
      contractAddress: "inj1qca7g560grtpwfvp8fal8f6gd7aumz6vx923es",
      stakingAddress: "inj17dmayyv6djllu3pfvmdvydvjepgryhesfeycjd",
      token0: USDT,
      token1: USDC,
    },
    {
      name: "WBTC-WETH LP",
      lpAddress: "inj186gq59cnpny6drstwe3d23s50z2hl27ynyzswp",
      contractAddress: "inj1p7lmag2em6j6e4ncgqjjz3j88e6lc2c098tla2",
      stakingAddress: "inj17lujph4ydmn57gw78he5ljxkjvqq9nh080gwv2",
      token0: WETH,
      token1: WBTC,
    },

  ],
  testnet: [
    {
      name: "INJ-USDT LP",
      lpAddress: "inj1knxx8crp0auxyvx8hn3jjl7htfsandx5xf0n8q",
      contractAddress: "inj1qppa5008z4ekt6tq7ax8ryre0y0w9lrhpjsnup",
      stakingAddress: "inj1624rmgycjv8xfyu4xeprguju2smcq46c94x8hd",
      token0: serializeToken("INJ", "Injective", "inj", 18, TokenType.NATIVE),
      token1: serializeToken(
        "USDT",
        "USD Tether",
        "peggy0x87aB3B4C8661e07D6372361211B96ed4Dc36B1B5",
        6,
        TokenType.NATIVE,
      ),
    },
  ],
}

export const farms: { mainnet: FarmConfig[]; testnet: FarmConfig[] } = {
  mainnet: [
    {
      name: "INJ-USDT LP",
      lpAddress: "inj15xk5d4d3we8z9s9avcqfns2xsrqq9u5mgaw6q6",
      contractAddress: "inj1h0mpv48ctcsmydymh2hnkal7hla5gl4gftemqv",
      stakingAddress: "inj1ewdcesmpdq5vz67a2rmfr682gjqecedymqm74f",
      token0: INJ,
      token1: USDT,
    },
    {
      name: "NINJA-INJ LP",
      lpAddress: "inj1q62knrccf8n2386jzzv2plr6rat2lfvx95muqv",
      contractAddress: "inj1efdrt4s78gffnntlqlhcctu3h0ndsd0nr86edc",
      stakingAddress: "inj1eendvgucfvftcwmp4gt40nn9twvpnen025q9ru",
      token0: NINJA,
      token1: INJ,
    },
    {
      name: "KIRA-INJ LP",
      lpAddress: "inj1nssppa309t2pty5agwrmrjqx9md9764ntesce6",
      contractAddress: "inj1eswdzx773we5zu2mz0zcmm7l5msr8wcss8ek0f",
      stakingAddress: "inj1fafdyxs0uz2fmj9duqvdy83hrpr39p8c29x67w",
      token0: KIRA,
      token1: INJ,
    },
    {
      name: "GINGER-INJ LP",
      lpAddress: "inj1an95wrjrhwyw2qteel0j0pllh9tvjh0qlcm98u",
      contractAddress: "inj1uugfny4cqxq0jg9vd6svyunhmdlk2s6wjdftj8",
      stakingAddress: "inj1v4e2355xcf8eueqf3leyjws50h8mf7584n27x0",
      token0: GINGER,
      token1: INJ,
    },
    {
      name: "ATOM-INJ LP",
      lpAddress: "inj1e4r4gcx3z4f2cey2wyy27zcnr8tvjs0zrdvtvs",
      contractAddress: "inj194zp3wnyd48cvlpa2nudq4w349a2cwsk2rug7p",
      stakingAddress: "inj1fvf94zuzz8ral6s0wtvm93n2hxcln20vprdwcl",
      token0: ATOM,
      token1: INJ,
    },
    {
      name: "SOL-INJ LP",
      lpAddress: "inj1vr057m6msmv47zv35mfcmqswsg7gj0rmr36ghd",
      contractAddress: "inj1nv6c9yu3nw7jw3d40ckr6fp6kp6j82edcf3m36",
      stakingAddress: "inj1kcuhctfum6nu3x338c3tlew75ualadj0zl54gv",
      token0: SOL,
      token1: INJ,
    },
    {
      name: "TIA-INJ LP",
      lpAddress: "inj1cghvjzl7qp46r2e7sw9x2m2gsn8qt347wts9lh",
      contractAddress: "inj1vqe9v9mkq9t8f09jh94k9k4lhajrd9q4fdp3nz",
      stakingAddress: "inj1p06kgstj5yt58pwwu9w06t8amk9tq8y84efkw8",
      token0: TIA,
      token1: INJ,
    },
    {
      name: "WETH-USDT LP",
      lpAddress: "inj15v3wrnt8gq720xt2jsepyyvuluhm78msys4r5s",
      contractAddress: "inj13p9q5hqudqrmryz6f0t7j9yatacw9rueuy6n8w",
      stakingAddress: "inj1af5ku4yt9yzzwaea7hvn4es3lc0h2j2h85jjga",
      token0: WETH,
      token1: USDT,
    },
    {
      name: "WBTC-INJ LP",
      lpAddress: "inj1ytl5y7plqak300e42akc3pzn2j9hp35lw2pv3k",
      contractAddress: "inj1zcmmxmxdw72s0xur2r2h6dfdzs6akl7n08twdp",
      stakingAddress: "inj1pawjunx8722tyetpn5mp87r05f937mekuhlfnc",
      token1: INJ,
      token0: WBTC,
    },
    {
      name: "WETH-INJ LP",
      lpAddress: "inj1jqllyf6ta8z4l8whxj3jgjyfmucvpfl7tp4h3v",
      contractAddress: "inj18sf663n6zlh6unevsdv4vrfeqdkc0ze2ktu3aj",
      stakingAddress: "inj13ne0hfwnrl75e4fpffx6sdx22u5cdy9g3axyz8",
      token0: WETH,
      token1: INJ,
    },
    {
      name: "USDT-USDC LP",
      lpAddress: "inj1w9z5t04v2jl6r85s8e0984f4v2drdpq8fnl6hs",
      contractAddress: "inj1qca7g560grtpwfvp8fal8f6gd7aumz6vx923es",
      stakingAddress: "inj1neu85xla0ullvlz87a2yyw7rgzgtajxufdca2a",
      token0: USDT,
      token1: USDC,
    },
    {
      name: "WBTC-WETH LP",
      lpAddress: "inj186gq59cnpny6drstwe3d23s50z2hl27ynyzswp",
      contractAddress: "inj1p7lmag2em6j6e4ncgqjjz3j88e6lc2c098tla2",
      stakingAddress: "inj1zv037es2j2lzdgfr9nfjxd0jzdpq4uur65xapf",
      token0: WETH,
      token1: WBTC,
    },
  ],
  testnet: [
    {
      name: "INJ-USDT LP",
      lpAddress: "inj1knxx8crp0auxyvx8hn3jjl7htfsandx5xf0n8q",
      contractAddress: "inj1qppa5008z4ekt6tq7ax8ryre0y0w9lrhpjsnup",
      stakingAddress: "inj1624rmgycjv8xfyu4xeprguju2smcq46c94x8hd",
      token0: serializeToken("INJ", "Injective", "inj", 18, TokenType.NATIVE),
      token1: serializeToken(
        "USDT",
        "USD Tether",
        "peggy0x87aB3B4C8661e07D6372361211B96ed4Dc36B1B5",
        6,
        TokenType.NATIVE,
      ),
    },
  ],
}

export const getFarmByLpAddress = (address: string) => {
  const _farms = NETWORK === Network.Mainnet ? farms.mainnet : farms.testnet
  return _farms.find((item) => item.lpAddress === address)
}
