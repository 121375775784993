export enum NetworkKey {
  MAINNET = "mainnet",
  TESTNET = "testnet",
}

interface InjectiveNetwork {
  factory: string;
  service: string;
  multicall: string;
  dashboard: string | undefined;
  router: string;
  fee: {
    gasPrice: string;
    amount: string;
    gas: string;
  };
}

export const AVAILABLE_CHAIN_ID_LIST = ["injective-1", "injective-888"];

const injectiveNetworks: Record<string, InjectiveNetwork> = {
  mainnet: {
    factory: "inj1pc2vxcmnyzawnwkf03n2ggvt997avtuwagqngk",
    multicall: "inj1578zx2zmp46l554zlw5jqq3nslth6ss04dv0ee",
    service: "https://api.dojo.trading",
    dashboard: "https://data.dojo.trading/dashboard",
    // dashboard: "http://localhost:8877/dashboard",
    router: "inj1t6g03pmc0qcgr7z44qjzaen804f924xke6menl",
    fee: { gasPrice: "0.01", amount: "1518", gas: "20000000000000000" },
  },
  testnet: {
    factory: "inj14mxpetzg9sur0g6m39zu9m9n2ajxvlx4ytlgq3",
    multicall: "inj1dw8324k4sv4uwqjz730clzdd6ac975pu7gp3rd",
    service: "https://api-staging.dojo.trading",
    // service:
    //   process.env.REACT_APP_TESTNET_SERVICE_URL ||
    //   "https://api-pisco.terraswap.io/",
    dashboard: "https://data.dojo.trading/dashboard",
    router: "inj1hz3qjp6sh36ch0kv6v6uj7z0ngucxj5mgetyqk",
    fee: { gasPrice: "0.01", amount: "1518", gas: "200000000000000000" },
  },
};

export default injectiveNetworks;
