import {
  WalletProvider,
  WalletControllerChainOptions,
} from "@terra-money/wallet-provider";
import { PropsWithChildren, useEffect, useState } from "react";
import ConnectWalletModal from "./ConnectWalletModal";
import { getChainOptions } from "../libs/getChainOptions";
import useStore from "../store";

const WalletConnectProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const showWalletConnectModal = useStore((s) => s.showWalletConnectModal);
  const setShowWalletConnectModal = useStore(
    (s) => s.setShowWalletConnectModal
  );

  const [chainOptions, setChainOptions] =
    useState<WalletControllerChainOptions>();

  useEffect(() => {
    getChainOptions().then((chainOptions) => setChainOptions(chainOptions));
  }, []);

  return chainOptions ? (
    <WalletProvider {...chainOptions}>
      <ConnectWalletModal
        open={showWalletConnectModal}
        onClose={() => setShowWalletConnectModal(false)}
      />
      {children}
    </WalletProvider>
  ) : (
    <></>
  );
};
export default WalletConnectProvider;
