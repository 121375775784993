/**
* This file was automatically generated by @cosmwasm/ts-codegen@0.35.3.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the @cosmwasm/ts-codegen generate command to regenerate this file.
*/

import { Uint128, ConfigResponse, Cw20HookMsg, ExecuteMsg, Binary, Cw20ReceiveMsg, InstantiateMsg, QueryMsg, Decimal, StakerInfoResponse, StateResponse } from "./StakingPool.types";
import { CamelCasedProperties } from "type-fest";
export abstract class StakingPoolExecuteMsgBuilder {
  static receive = ({
    amount,
    msg,
    sender
  }: CamelCasedProperties<Extract<ExecuteMsg, {
    receive: unknown;
  }>["receive"]>): ExecuteMsg => {
    return {
      receive: ({
        amount,
        msg,
        sender
      } as const)
    };
  };
  static unbond = ({
    amount
  }: CamelCasedProperties<Extract<ExecuteMsg, {
    unbond: unknown;
  }>["unbond"]>): ExecuteMsg => {
    return {
      unbond: ({
        amount
      } as const)
    };
  };
  static withdraw = (): ExecuteMsg => {
    return {
      withdraw: ({} as const)
    };
  };
  static migrateStaking = ({
    newStakingContract
  }: CamelCasedProperties<Extract<ExecuteMsg, {
    migrate_staking: unknown;
  }>["migrate_staking"]>): ExecuteMsg => {
    return {
      migrate_staking: ({
        new_staking_contract: newStakingContract
      } as const)
    };
  };
}
export abstract class StakingPoolQueryMsgBuilder {
  static config = (): QueryMsg => {
    return {
      config: ({} as const)
    };
  };
  static state = ({
    blockHeight
  }: CamelCasedProperties<Extract<QueryMsg, {
    state: unknown;
  }>["state"]>): QueryMsg => {
    return {
      state: ({
        block_time: blockHeight
      } as const)
    };
  };
  static stakerInfo = ({
    blockHeight,
    staker
  }: CamelCasedProperties<Extract<QueryMsg, {
    staker_info: unknown;
  }>["staker_info"]>): QueryMsg => {
    return {
      staker_info: ({
        block_time: blockHeight,
        staker
      } as const)
    };
  };
}