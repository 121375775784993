// need to write condition where fetching will occur
// need to store any failed requests and attempt after 30s. after 2 tries just give up
// when users refresh balances, only fetch the specific farm to reduce calls
// using axios with throttling should help: https://www.npmjs.com/package/axios-rate-limit

import { StateCreator } from "zustand"
import { FarmData, FarmsMap, FarmsSlice } from "../interfaces/farms.interface"
import { INJECTIVE_BURN_WALLET } from "constants/constants"

export const farmsSlice: StateCreator<FarmsSlice, [], [], FarmsSlice> = (
  set,
  get,
) => ({
  farmsInitialLoadComplete: false,
  setInitialFarmsLoaded: () =>
    set((state) => ({
      ...state,
      farmsInitialLoadComplete: true,
    })),
  selectedFarmsVersion: 2,
  setSelectedFarmsVersion: (version: number) =>
    set((state) => ({
      ...state,
      selectedFarmsVersion: version,
    })),
  farmWallet: undefined,
  setFarmWallet: (wallet: string) =>
    set(() => ({
      farmWallet: wallet,
    })),
  farmsByWallet: {},
  setAllFarms: (farms: FarmsMap, version: number, wallet: string) => {
    const key = `v${version}`
    const farmWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET
    set((state) => {
      return {
        ...state,
        farmsByWallet: {
          ...state?.farmsByWallet,
          [farmWallet]: {
            ...state?.farmsByWallet?.[farmWallet],
            [key]: {
              ...state.farmsByWallet?.[farmWallet]?.[key],
              ...farms,
            },
          },
        },
      }
    })
  },
  setFarm: (name: string, farm: FarmData, version: number, wallet: string) => {
    const key = `v${version}`
    const farmWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET
    set((state) => {
      return {
        ...state,
        farmsByWallet: {
          ...state?.farmsByWallet,
          [farmWallet]: {
            ...state?.farmsByWallet?.[farmWallet],
            [key]: {
              ...state.farmsByWallet?.[farmWallet]?.[key],
              [name]: farm,
            },
          },
        },
      }
    })
  },
  selectedFarm: undefined,
  setSelectedFarm: (farm: FarmData) =>
    set((s) => ({
      selectedFarm: farm,
    })),
})
